import gql from 'graphql-tag';

export const GENERATE_TOKEN = gql`
  mutation ($email: String!, $password: String, $authToken: String, $authType: String) {
    generateCustomerToken(email: $email, password: $password, oauth_token: $authToken, oauth_type: $authType) {
      token
    }
  }
`;

export const REVOKE_TOKEN = gql`
  mutation {
    revokeCustomerToken {
      result
    }
  }
`;

export const EMAIL_AVAILABLE = gql`
  query ($email: String!) {
    isEmailAvailable(email: $email) {
      is_email_available
    }
  }
`;
