const USER = 'user';

export const getUser = () => {
    const user = JSON.parse(localStorage.getItem(USER));
    return user
};
export const saveUser = user => {
    return localStorage.setItem(USER, JSON.stringify(user));
}
export const deleteUser = () => {
    return localStorage.removeItem(USER);
}
