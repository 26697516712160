import { useEffect, useRef } from "react";

const MOUNT_STATE = Object.freeze({
  WILL_MOUNT: 0,
  MOUNTED: 1,
  UNMOUNTED: 2,
})

const useMountState = () => {
  const unmounted = useRef(MOUNT_STATE.WILL_MOUNT);

  useEffect(() => {
    unmounted.current = MOUNT_STATE.MOUNTED;
    return () => unmounted.current = MOUNT_STATE.UNMOUNTED;
  }, []);

  return {
    isMounted: () => unmounted.current === MOUNT_STATE.MOUNTED,
    isUnmounted: () => unmounted.current === MOUNT_STATE.UNMOUNTED,
  };
};

export default useMountState;
