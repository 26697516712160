import React from 'react';
import emmezetaPlaceholder from "../assets/img/emmezeta-placeholder.png";
import parse from "html-react-parser";
import Helmet from "react-helmet";
import {useQuery} from "@apollo/react-hooks";
import {GET_BACKEND_CONFIG} from "../commonQueries";
import {useLocation} from "react-router-dom";
import readConfig from "../utils/readClientConfiguration";
import appConfiguration from "../../config/default.json";

export default () => {
    const {baseUrl} = appConfiguration.config;
    const {data: backendConfig, loading: backendConfigLoading} = useQuery(GET_BACKEND_CONFIG);
    const location = useLocation();
    const currentUrl=baseUrl +  location.pathname;
    let scriptsSrc = [];
    const {default_meta_description, head_scripts, samito} = backendConfig.configProvider;

    if (parseInt(samito.samito_enable) == 1) {
        const {sid:samito_sid, url: samito_url } = readConfig(appConfiguration, 'samito');

        scriptsSrc.push({src: `${samito_url}/samito.js`, type: 'text/javascript', defer: true, async: true});
        const encodedUrl=encodeURIComponent(baseUrl +  location.pathname);
        scriptsSrc.push({
            type: 'text/javascript',
            innerHTML: `var SavecartNS={sid: '${samito_sid}',url: '${encodedUrl}'};`
        })
    }

    return (
        <Helmet
            defaultTitle="Emmezeta webshop - sigurna online trgovina"
            titleTemplate="%s"
            script={scriptsSrc}
        >
            <meta name="description" content={default_meta_description}/>
            <meta name="keywords" content=""/>
            <meta name="theme-color" content="#fff"/>
            <meta name="format-detection" content="telephone=yes"/>
            <meta property="og:title" content="Emmezeta webshop - sigurna online trgovina"/>
            <meta property="og:type" content="website"/>
            <meta property="og:description" content={default_meta_description}/>
            <meta property="og:url" content="/"/>
            <meta property="og:image" content={emmezetaPlaceholder}/>
            <meta property="og:image:height" content="240"/>
            <meta property="og:image:width" content="240"/>
            <link rel="canonical" href={currentUrl}/>


            {(typeof head_scripts === 'string') ? parse(head_scripts) : null}
        </Helmet>
    )
}