import React, {useEffect, useState} from 'react';

const CompareRow = ({attribute, items}) => {
    const prepareStorefrontAttributes = ((product) => {
        const out = {}
        for (let key in product.storefront_attributes) {
            if (Array.isArray(product.storefront_attributes[key])) {
                product.storefront_attributes[key].forEach((attr) => {
                    out[attr.code] = {
                        code: attr.code,
                        value: attr.value
                    }
                })
            }
        }
        return out
    })

    const [compareItems, setCompareItems] = useState([])

    const getValue = (code, item) => {
        if (code in item.product.attributes) {
            return item.product.attributes[code]
        }
        return item.attributes.find((attr) => {
                return attr.code == code
            }
        )
    }

    useEffect(() => {
        const _items = items.map((item) => {
            item.product = Object.assign({}, item.product,
                {
                    attributes: prepareStorefrontAttributes(item.product)
                }
            )
            return item
        })
        setCompareItems(_items)
    }, [items])

    const values = Array.from(new Set(items.map((item) => item.attributes[attribute.index].value)))
    const noDiff = values.length == 1
    if(noDiff && values[0]=='N/A'){
        return null
    }

    const classes = ['item-row'];
    if (!noDiff) {
        classes.push('diff')
    }

    return (
        <div className={classes.join(' ')}>
            <div className='info'>{attribute.label}</div>
            {
                compareItems.map((item) => {
                    const value = getValue(attribute.code, item)
                    return (
                        <div className='item'><span dangerouslySetInnerHTML={{__html: value.value}}/></div>
                    )
                })
            }
        </div>
    )
}

export default CompareRow