import React, {memo} from 'react';
import Filter from './Filter';
import { useModal } from '../../../hooks/useModal';
import Modal from '../../UI/Modal/Modal';

const MobileFilter = (props) => {
    const { isOpen, openModal, closeModal } = useModal(); // TODO: Mby move cause react

    return <div className={"block filter mobile-filter-block"}>
        <div onClick={openModal} className={"block-title filter-title"}>
            <strong>Filtrirajte rezultate</strong>
        </div>
        <Modal style={{width: "100%", height: "100%"}} isOpen={isOpen} onClose={closeModal} isMobileFilter>
            <Filter {...props} isMobileFilter/>
        </Modal>
    </div>
}

export default MobileFilter;