import React from "react";
import { createPortal } from "react-dom";
import "./AddToCartMessagePopup.scss";

const AddToCartMessagePopup = ({ message, type }) => {
  const element = (
    <div className={`add-to-cart-message-popup add-to-cart-message-popup--${type}`}>
      {message}
    </div>
  );

  return createPortal(element, document.body);
};

export default AddToCartMessagePopup;
