import React, {memo} from 'react';
import {arrayOf, object} from 'prop-types';
import ProductItem from '../ProductItem/ProductItem';
import {T} from '@deity-io/falcon-i18n';
import './Products.scss';

// TODO: Rename all the attributes to be the same to reduce confusion, make sure not to break anything in the process

export const normalizeProductItem = item => {
    let {
        id,
        uid,
        name,
        brand,
        categories,
        sku,
        url_key,
        thumbnail,
        gallery,
        is_saleable,
        lifetime_status,
        is_on_order_only,
        guarantees,
        prices,
        special_price,
        canonical_url,
        emlabels,
        days_to_delivery,
        advanced_product_inventory,
        price_last_30,
        items,
    } = item;

    let firstImage = null;
    let thumbnailImage = null;
    let imageThumbnailLabel = null;
    let hoverImage = null;
    if (typeof gallery === 'object') {
        // Find the hover image
        hoverImage = gallery.find(x => x.is_hover === true);

        thumbnailImage = gallery.find(x => x.is_thumbnail === true);

        if(!thumbnailImage){
            const firstPosition = Math.min(...gallery.map(x => x.position));
            firstImage = gallery.find(x => x.position === firstPosition);
            firstImage = (firstImage && firstImage.url) || (thumbnail && thumbnail.url);

            thumbnailImage=gallery.find(x => x.position === firstPosition);
        }

        if (!hoverImage){
            const filtered=gallery.filter((x) => {
                return x.id!=thumbnailImage.id
            });
            if(filtered.length){
                hoverImage=filtered[0]
            }
        }

    }

    if (advanced_product_inventory) {
        const {enable_qty_increments, unit} = advanced_product_inventory
        if (parseInt(enable_qty_increments) === 1 || unit!='kom') {
            prices = prices.map((price) => {
                price.unit = unit
                return price
            })
        }
    }

    if(!thumbnailImage){
        thumbnailImage={
            medium_image_url: 'https://s3.emmezeta.hr/media/emmezeta-placeholder.png',
            small_image_url: 'https://s3.emmezeta.hr/media/emmezeta-placeholder.png',
            large_image_url: 'https://s3.emmezeta.hr/media/emmezeta-placeholder.png',
            url: 'https://s3.emmezeta.hr/media/emmezeta-placeholder.png',
        }
    }

    return Object.assign({}, {
        uid,
        id,
        sku,
        name,
        brand,
        categories,
        image: firstImage,
        imageLabel: imageThumbnailLabel,
        hoverImage,
        thumbnailImage,
        isHasStock: is_saleable,
        lifetimeStatus: lifetime_status,
        isOnOrder: is_on_order_only,
        guarantees,
        url: url_key,
        prices,
        special_price,
        canonical_url,
        emlabels,
        days_to_delivery,
        advanced_product_inventory,
        price_last_30,
        items,
    }, item)

}
export const normalizeProductItems = items => {
    return items.map(
        (item) => {
            return normalizeProductItem(item)
        }
    );
};

const Products = ({items, isCategory, isQuarticon}) => {
    if (!items) {
    return (
      <ul className="products">
        <h1>
          <T id="searchResult.noResults" />
        </h1>
      </ul>
    );
  }
  return (
    <ul className="products">
      {items &&
        normalizeProductItems(items).map((item) => (
          <ProductItem key={item.sku} product={item} isCategory={isCategory} isQuarticon={isQuarticon} />
        ))}
    </ul>
  );
};

Products.propTypes = {
  items: arrayOf(object),
};

export default memo(Products);
