import React, {memo} from "react";
import './InfoIcons.scss';

const InfoIcon = ({className, fill}) => {

    let classes=['icon'];
    classes.push(className);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={fill ? fill : 'black'}
            height="11.536491"
            width="11.536491"
            className={classes.join(' ')}
            viewBox="0 0 12 12" preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="matrix(0.08874224,0,0,0.08874224,-1.3311336,-1.3311336)"
            >
                <path
                    d="m 80,15 c -35.88,0 -65,29.12 -65,65 0,35.88 29.12,65 65,65 35.88,0 65,-29.12 65,-65 0,-35.88 -29.12,-65 -65,-65 z m 0,10 c 30.36,0 55,24.64 55,55 0,30.36 -24.64,55 -55,55 C 49.64,135 25,110.36 25,80 25,49.64 49.64,25 80,25 Z"
                />
                <path
                    d="m 57.373,18.231 a 9.3835,9.1153971 0 1 1 -18.767,0 9.3835,9.1153971 0 1 1 18.767,0 z"
                    transform="matrix(1.1989,0,0,1.2342,21.214,28.75)"
                />
                <path
                    d="m 90.665,110.96 c -0.069,2.73 1.211,3.5 4.327,3.82 l 5.008,0.1 V 120 H 60.927 v -5.12 l 5.503,-0.1 c 3.291,-0.1 4.082,-1.38 4.327,-3.82 V 80.147 C 70.792,75.268 64.461,76.034 60,76.179 V 71.105 L 90.665,70"
                />
            </g>
        </svg>
    )
}

export default memo(InfoIcon)