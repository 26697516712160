import React from 'react';
import {T} from '@deity-io/falcon-i18n';
import SlickSlider from 'react-slick';
import {Loader} from '@deity-io/falcon-ecommerce-uikit';
import ProductItem from '../../components/Category/ProductItem/ProductItem';
import './ProductSlider.scss';
import {useRecommendationsQuarticon} from "../../hooks/useQuarticonHooks";
import {normalizeProductItems} from "../../components/Category/Products/Products";
import useResponsive from "../../hooks/useResponsive";

const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: false,
  className: 'center',
  centerPadding: '1px',
  speed: 700,
  margin: 0,
  adaptiveHeight: true,
  slidesToShow: 5, // n items
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 1225,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        arrows: true,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 918,
      settings: {
        arrows: true,
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const QuarticonProductSlider = ({ type, isHome, categoryId, isCart,cnt= 3, sku=null}) => {
  const {isMobile} = useResponsive();
  const {
    recomendations,
    loading,
  } = useRecommendationsQuarticon(sku, type, categoryId);
  const mergedProducts = recomendations;
  if (loading) {
    return (
        <div className="loader-container">
          <Loader/>;
        </div>
    );
  }

  if (!recomendations) {
    return <></>;
  }

  const productItems = (mergedProducts && normalizeProductItems(mergedProducts)) || null;


  if(isMobile){
    return (
    <>
      {productItems && (
          <div className='products__list'>
            {!isHome && (
                <h3 className="quarticon-slider__title">
                  <T id="product.recommendedProducts" />
                </h3>
            )}
            <ul className='products'>

                {productItems.slice(0, 4).map((item,index) => <ProductItem key={index} product={item} isCms isQuarticon />)}

            </ul>
          </div>
      )}
    </>
    )
  }

  return (
    <>
      {productItems && (
        <div>
          {!isHome && (
            <h3 className="quarticon-slider__title">
              <T id="product.recommendedProducts" />
            </h3>
          )}
          <ul>
            <SlickSlider
              {...sliderSettings}
              {...{ slidesToShow: cnt }}
              {...{ slidesToScroll: cnt }}
              className="product-slider"
            >
              {productItems && productItems.map((item,index) => <ProductItem key={index} product={item} isCms isQuarticon />)}
            </SlickSlider>
          </ul>
        </div>
      )}
    </>
  );
};

export default React.memo(QuarticonProductSlider);
