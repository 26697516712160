import {useState, useEffect} from 'react';

const useResponsive = () => {
  const [isClient, setIsClient] = useState(false);

  const [isMobile, setIsMobile] = useState(() =>
    typeof window !== 'undefined' && window.matchMedia ? window.matchMedia('(max-width: 768px)').matches : null,
  );

  const [isDesktop, setIsDesktop] = useState(() =>
      typeof window !== 'undefined' && window.matchMedia ? window.matchMedia('(min-width: 1200px)').matches : null,
  );

  const handler = e => setIsMobile(e.matches);
  const handlerDesktop = e => setIsDesktop(e.matches);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(true);
      window.matchMedia('(max-width: 768px)').addEventListener('change', handler)
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('change', handler)
      }
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(true);
      window.matchMedia('(min-width: 1200px)').addEventListener('change', handlerDesktop);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('change', handler)
      }
    };
  }, []);

  return {
    isMobile: isClient ? isMobile : false,
    isDesktop: isClient ? isDesktop : false,
  };
};

export default useResponsive;
