import { I18nContext } from '@deity-io/falcon-i18n';
import { useContext } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { usePopup } from '../context/PopupContext';
import { useUserContext } from '../context/UserContext';
import { ADD_PRODUCT_TO_WISHLIST, GET_WISHLIST_PRODUCTS, REMOVE_PRODUCT_FROM_WISHLIST } from '../queries/wishlist.gql';
import { useCartContext } from '../context/CartContext';
import { addToWishListEvent } from '../hooks/useAnalytics';

const useWishlist = (sku, name, id, price) => {
  const { t } = useContext(I18nContext);
  const { user } = useUserContext();
  const { updateCartItem } = useCartContext();
  const history = useHistory();
  const { showAddToCartPopupMessage } = usePopup();
  const pagination = {
    variables: {
      pageSize: 12
    }
  };

  const { data: wishlistData } = useQuery(GET_WISHLIST_PRODUCTS, {
    ...pagination,
    skip: !user,
  });

  const [addToWishlist, { loading: addLoading }] = useMutation(ADD_PRODUCT_TO_WISHLIST, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_WISHLIST_PRODUCTS, ...pagination }],
  });

  const [removeFromWishlist, { loading: removeLoading }] = useMutation(REMOVE_PRODUCT_FROM_WISHLIST, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_WISHLIST_PRODUCTS, ...pagination }],
  });

  const wishlist = wishlistData && wishlistData.getWishlistProducts && wishlistData.getWishlistProducts.wishlists[0];
  const wishlistItem = wishlist && wishlist.items_v2 && wishlist.items_v2.items.find(item => item.product.sku === sku);
  const loading = addLoading || removeLoading;

  const toggleInWishlist = async event => {
    event.preventDefault();

    if (!user) {
      history.push('/customer/account');
      return;
    }

    if (loading) {
      return;
    }

    try {
      if (wishlistItem) {
        await removeFromWishlist({
          variables: { wishlistId: wishlist.id, wishlistItemsIds: wishlistItem.id },
        });

        const wishlistRemove = new CustomEvent('wishlist_remove', {});
        window.dispatchEvent(wishlistRemove)
        showAddToCartPopupMessage(`${name}${t('cart.removedFromWishlist')}`, 'success', 2000);
      } else {
        await addToWishlist({
          variables: { wishlistId: wishlist.id, wishlistItems: { sku, quantity: 1 } },
        });
        if (id) {
          updateCartItem(id, 0);
        }
        showAddToCartPopupMessage(`${name}${t('cart.movedToWishlist')}`, 'success', 2000);
        addToWishListEvent(sku, name, price)
      }
    } catch (err) {
      console.log(err);
    }
  };

  return { toggleInWishlist, loading, isInWishlist: !!wishlistItem };
};

export default useWishlist;
