import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {useMutation, useQuery} from 'react-apollo';
import {useHistory} from 'react-router-dom';
import {GET_WISHLIST_PRODUCTS} from '../components/PersonalArea/PersonalAreaWishlist/wishlistQuery';
import {GENERATE_TOKEN, REVOKE_TOKEN} from '../queries/auth';
import {GET_CART} from '../queries/cart';
import {GET_CARD, GET_CUSTOMER} from '../queries/customer';
import {useCartContext} from "./CartContext";

const Context = createContext();

export const useUserContext = () => {
  return useContext(Context);
};

export const UserProvider = ({ children }) => {
  const history = useHistory();
  const {cart, refetchCart} = useCartContext();

  const [compare,setCompare]=useState([])
  const userResult = useQuery(GET_CUSTOMER, { ssr: false });
  const userData = userResult.data && userResult.data.customer;
  const { data: card } = useQuery(GET_CARD, {
    fetchPolicy: 'no-cache',
    skip: !userData,
  });

  useEffect(() => {
    refetchCart()
  }, [userData])
  const { success } = (card && card.historyCustomerLoyaltyCard) || {};
  const [generateToken, generateResult] = useMutation(GENERATE_TOKEN, {
    refetchQueries: [{ query: GET_CUSTOMER }, { query: GET_CART }, { query: GET_WISHLIST_PRODUCTS }],
  });

  const [revokeToken, revokeResult] = useMutation(REVOKE_TOKEN, {
    refetchQueries: [{ query: GET_CUSTOMER }, { query: GET_CART }, { query: GET_WISHLIST_PRODUCTS }],
  });

  const logIn = useCallback(
    async (email, password) => {
      const result = await generateToken({
        variables: {
          email,
          password,
        },
      });

      return result;
    },
    [generateToken, history],
  );

  const logOut = useCallback(async () => {
    const result = await revokeToken();
    history.push('/customer/account/logoutSuccess');
    return result;
  }, [revokeToken, history]);

  const store = {
    user: userData,
    loading: userResult.loading || generateResult.loading || revokeResult.loading,
    error: generateResult.error,
    userStatus: userResult.networkStatus,
    isLoyaltyCardActive: success,
    cart,
    logIn,
    logOut,
    compare,
    setCompare
  };

  return <Context.Provider value={store}>{children}</Context.Provider>;
};
