import gql from 'graphql-tag';
export const GET_SHOP_CONFIG = gql`
    query getShopConfig {
        backendConfig {
            shop {
                headScripts
                defaultMetaDescription
            }
        }
    }
`;
export const GET_BACKEND_CONFIG = gql`
  query getBackendConfig {
    configProvider {
      action_id
      enterprise_id
      tag_id
      gtm_id
      gtm_is_active
      analytics_id
      facebook_client_id
      google_client_id
      default_meta_description
      head_scripts
      smind_isActive
      smind_key
      smind_version
      captchaWebsiteKey
      ccc_isActive
      ccc_token
      ccc_method
      flix_id
      flix_language
      flix_active_brands
      edrone {
        app_id
        edrone_enable
        app_secret
        newsletter_form_id
      }
      samito {
        feature_url
        production_url
        samito_enable
        samito_feature
        samito_production
      }
      bosch {
        is_active
        api_key
        locale
      }
    }
  }
`;

export const BASE_CURRENCY = gql`
  query {
    backendConfig {
      shop {
        currency {
          available_currency_codes
          base_currency_code
          default_display_currency_symbol
          exchange_rates {
            rate
            currency_to
          }
        }
      }
    }
  }
`;

export const GET_STORE_CONFIG = gql`
    query storeConfig {
        storeConfig {
            base_url
            header_logo_src
        }
    }
`;