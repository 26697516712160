import gql from "graphql-tag";
import {LISTING_PRODUCTS_FRAGMENT, ProductFields} from "../../routes/routeQuery";

export const COMPARE_QUERY=gql`
    mutation createCompareList($input: CreateCompareListInput!) {
        createCompareList(input: $input) {
            __typename
            item_count
            attributes {
                __typename
                code
                label
            }
            items{
                __typename
                attributes{
                    value
                    code
                    __typename
                }
                product{
                    ...ProductFields
                }
            }
        }
    }
    ${ProductFields}
`;