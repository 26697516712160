import React from 'react';
import useResponsive from '../../hooks/useResponsive';
import Filter from "../Category/Filter/Filter";
import MobileFilter from "../Category/Filter/MobileFilter";
import {useListingContext} from "../../context/ListingContext";

const ProductFilters = ({filterHandle}) => {
    const {isMobile} = useResponsive()

    const {
        aggregations,
        listingParams
    } = useListingContext();

    const currentFilters = listingParams.filters;
    return (
        <>

            {!isMobile && (
                <Filter
                    aggregations={aggregations}
                    currentFilters={currentFilters}
                    filterHandle={filterHandle}
                />
            )}
            {isMobile && (
                <MobileFilter
                    aggregations={aggregations}
                    currentFilters={currentFilters}
                    filterHandle={filterHandle}
                />
            )}
        </>
    )
}

export default ProductFilters