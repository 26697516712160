// TODO: fix configuration, don't read from config/production.json for security reasons
import defaultConfiguration from '../../config/default.json';
import readConfig from './readClientConfiguration';

export default function formatLocalePrice(
    price,
    maximumFractionDigits = 2,
    withCurrencySymbol = true,
    minimumFractionDigits = 2,
    baseCurrency = 'EUR',
) {
  const countryCode = readConfig(defaultConfiguration, 'countryCode');
  // const currencySymbol = readConfig(defaultConfiguration, 'currencySymbol');

  if (price !== 0 && !price) {
    // This is valid since some products don't have special prices for example
    return '';
  }

  price = parseFloat(price);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(price)) {
    console.warn('Price passed to formatter, got a NaN');
    return '';
  }

  const options = {
    style: 'decimal',
    useGrouping: true,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1
  };

  //fix - przeglądarski dla SI czasami wyświetlają kropkę zamiast przecinka
  const fixedCountryCode = countryCode == 'sl-SI' ? 'hr-HR' : countryCode;
  const formattedPrice = new Intl.NumberFormat(fixedCountryCode, options).format(price);

  if (withCurrencySymbol) {
    return `${formattedPrice} ${currencySymbol(baseCurrency)}`;
  }

  return formattedPrice;
}


export const currencySymbol =(currency)=>{
  switch (currency){
    case 'EUR':
      return '€';
    case 'RSD':
      return 'din.';
    case 'HRK':
      return 'kn';
    default:
      return currency;
  }
}
