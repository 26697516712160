import React, {useEffect} from 'react';
import {
    useHistory,
    useParams
} from "react-router-dom";
import {NEWSLETTER_CONFIRM_EMAIL} from "../../queries/newsletter";
import {useMutation} from "react-apollo";
import {usePopup} from "../../context/PopupContext";

const SubscriberConfirm = (props) => {
    let {confirmId, confirmCode} = useParams();
    const history = useHistory();

    const [mutateFunction, {data, loading, error}] = useMutation(NEWSLETTER_CONFIRM_EMAIL,
        {
            variables:
                {
                    id: confirmId,
                    code: confirmCode
                }
        }
    );
    const {showAddToCartPopupMessage} = usePopup();

    useEffect(() => {
        mutateFunction();
    }, [])
    if (data) {
        showAddToCartPopupMessage(data.confirmEmailToNewsletter.message, 'success', 4000);
        history.push("/");
    }

    return (
        <div/>
    )
}
export default SubscriberConfirm;