import * as React from "react";
import FilterOtherType from './FilterTypes/FilterOtherType';
import FilterRangeType from './FilterTypes/FilterRangeType';
import { withApollo } from "react-apollo";

const FilterItem = (props) => {
    if(props.type === "FilterRangeTypeInput"){
        return <FilterRangeType {...props}/>
    }
    return <FilterOtherType {...props}/>
}

export default withApollo(FilterItem);