import React, { useState, createContext } from 'react';
import { getToken } from '../repositories/token';
import { getUser } from '../repositories/user';

export const Context = createContext(null);

export const ContextProvider = ({ children }) => {
  let savedUser;

  if (typeof window !== 'undefined') {
    savedUser = !!getToken() === true ? getUser() : null;
  }

  const [user, setUser] = useState(savedUser);
  const [quantity, setQuantity] = useState(1);
  const [selectUrl, setSelectUrl] = useState(null);
  const [select, setSelect] = useState(null);

  const store = {
    user: { user, setUser },
    groupedProductQuantity: { quantity, setQuantity },
    groupedProductSelectValue: { select, setSelect },
    selectUrl,
    setSelectUrl,
  };

  return <Context.Provider value={store}>{children}</Context.Provider>;
};
