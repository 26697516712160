import React, {createContext, useContext} from 'react';
import {Config, Recommendation, Track} from 'qon-connection';
import readConfig from "../utils/readClientConfiguration";
import appConfiguration from "../../config/default.json";

const QuarticonContext = createContext(null);

export const useQuarticonContext = () => {
    return useContext(QuarticonContext);
};

export const QuarticonContextProvider = ({children}) => {

    const quarticonConfig = readConfig(appConfiguration, 'quarticon');

    const config = quarticonConfig && quarticonConfig.is_active &&
        new Config.Config({customerSymbol: quarticonConfig.customer_symbol, host: quarticonConfig.host_url});

    const tracker = config && new Track.Tracker(config);
    const recommendationEngine = config && new Recommendation.Recommendation(config);

    return (
        <QuarticonContext.Provider
            value={{
                tracker,
                recommendationEngine,
            }}
        >
            {children}
        </QuarticonContext.Provider>
    );
};
