import React, {createContext, useContext, useEffect, useState} from 'react';

const CompareContext = createContext();

export const CompareProvider = ({children}) => {
    const [compareProducts, setCompareProducts] = useState(
        () => {
            try {
                const items = window.localStorage.getItem('compare');
                return items ? JSON.parse(items) : [];
            } catch (error) {
                return [];
            }
        }
    );


    useEffect(()=>{
        window.localStorage.setItem('compare', JSON.stringify(compareProducts));
    },[compareProducts])
    const addToCompare = (product) => {
        setCompareProducts([...compareProducts, product]);
    }
    const removeCompare = (sku) => {
        setCompareProducts([...compareProducts.filter((product)=>product.sku!=sku)]);
    }
    return (
        <CompareContext.Provider value={
            {
                addToCompare,
                removeCompare,
                compareProducts,
                setCompareProducts
            }
        }>
            {children}
        </CompareContext.Provider>
    );
};

export const useCompare = () => {
    return useContext(CompareContext);
}