import React from 'react';
import { T } from '@deity-io/falcon-i18n';

const PageSize = ({ pageSize, onPageSizeChange }) => {
  return (
    <div className="pageSizeDiv">
      <span className="sortSpan">
        <T id="category.pageSizeLabel" />
      </span>
      <div className="pageSizeOptions">
        <select defaultValue={pageSize.toString()} onChange={onPageSizeChange}>
          <option value="12">12</option>
          <option value="24">24</option>
          <option value="36">36</option>
          <option value="96">96</option>
        </select>
      </div>
      <span className="pageSizeLabel"><T id="cart.perPage" /></span>
    </div>
  );
};

export default PageSize;
