import React from 'react';
import { I18n } from '@deity-io/falcon-i18n';

const SortProducts = ({ sorting, onSortChange, isSearch }) => {
  return (
    <I18n>
      {t => (
        <div className="sortBySection">

          <div className="sortByOptions">
            <select onChange={onSortChange} defaultValue={`${sorting.product_list_order}-${sorting.product_list_dir}`}>
              <option value="default">{t('category.sortBy')} {t(isSearch ? 'searchResult.sortProducts' : 'category.sortProducts')}</option>
              <option value="price-ASC">{t('category.sortBy')} {t('category.priceAsc')}</option>
              <option value="price-DESC">{t('category.sortBy')} {t('category.priceDesc')}</option>
              <option value="name-ASC">{t('category.sortBy')} {t('category.nameAsc')}</option>
              <option value="name-DESC">{t('category.sortBy')} {t('category.nameDesc')}</option>
            </select>
          </div>
        </div>
      )}
    </I18n>
  );
};

export default SortProducts;
