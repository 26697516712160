import React from 'react';
import { Link } from 'react-router-dom';
import {selectProductEvent} from '../../../hooks/useAnalytics';

const ChooseLink = ({ link, children, className, product }) => {
  return (
        <Link to={`/${link}`} className={className} onClick={() => selectProductEvent(product)}>
          {children}
        </Link>
  );
};

export default ChooseLink;
