import productionConfiguration from '../../config/default.json';
import readConfig from '../utils/readClientConfiguration';

const countryCode = readConfig(productionConfiguration, 'countryCode');
const analyticsCurrencyCode = readConfig(productionConfiguration, 'currencyCode');

const getPrices = (product) => {
  let regularPrice = parseFloat(product.prices[0].amount);
  let sellPrice = parseFloat(product.prices[0].amount);

  product.prices.forEach((price) => {
    if (price.price_type == 'simple_clearance') {
      sellPrice = parseFloat(price.amount, 2)
    }
  })
  const discount = parseFloat((regularPrice - sellPrice).toFixed(2), 2)


  return {
    regularPrice,
    sellPrice,
    discount: discount
  }
}


function listCategories(categories) {
  let category = '';
  if (categories) {
    category = categories.map(y => y.name).join('/') || '';
  }
  return category;
}

const getItems = (items) => {
  const out = [];
  items.forEach((item) => {
    const prices = getPrices(item)
    const _item = {
      item_id: item.sku || item.barcode || '',
      item_name: item.name,
      index: 0,
      price: prices.regularPrice,
      discount: prices.discount,
      item_brand: item.brand,
      item_category: typeof item.categories[0] !== 'undefined' ? item.categories[0].name : null,
      item_category2: typeof item.categories[1] !== 'undefined' ? item.categories[1].name : null,
      item_category3: typeof item.categories[2] !== 'undefined' ? item.categories[2].name : null,
      item_category4: typeof item.categories[3] !== 'undefined' ? item.categories[3].name : null,
      item_category5: typeof item.categories[4] !== 'undefined' ? item.categories[4].name : null,
      item_image: item.image ? item.image : item.gallery && item.gallery.length > 0 ? item.gallery[0].url : null,
      quantity: 1,
    }
    out.push(_item)
  })
  return out
}


export const addToCartEvent = (item, quantity) => {

  if (!item) {
    return;
  }
  item.quantity=quantity
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  const prices = getPrices(item)
  const data = {
    event: 'add_to_cart',
    ecommerce: {
      currency: analyticsCurrencyCode,
      value: prices.sellPrice,
      items: getItems([item]),
    }
  };
  return window.dataLayer.push(data);
};

export const removeFromCartEvent = (item, quantity) => {
  if (!item) {
    return;
  }
  item.quantity=quantity
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  const prices = getPrices(item)
  return window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      currency: analyticsCurrencyCode,
      value: prices.sellPrice,
      items: getItems([item]),
    }
  });
};

export const productDetailEvent = (product)=> {
  if (!product) {
    return;
  }
  if (product.prices.length==0) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  const data = {
    event: 'view_item',
    ecommerce: {
      currency: analyticsCurrencyCode,
      price: product.prices.length > 0 ? parseFloat(product.prices[0].amount, 2) : null,
      items: [
        {
          item_id: product.sku,
          item_name: product.name,
          index: 0,
          price: product.prices.length > 0 ? parseFloat(product.prices[0].amount, 2) : null,
          quantity: 1,
          item_brand: product.brand,
          item_category: typeof product.categories[0] !== 'undefined' ? product.categories[0].name : null,
          item_category2: typeof product.categories[1] !== 'undefined' ? product.categories[1].name : null,
          item_category3: typeof product.categories[2] !== 'undefined' ? product.categories[2].name : null,
          item_category4: typeof product.categories[3] !== 'undefined' ? product.categories[3].name : null,
          item_category5: typeof product.categories[4] !== 'undefined' ? product.categories[4].name : null,
          item_image: product.gallery.length > 0 ? product.gallery[0].url : null
        }
      ]
    },
  }
  return window.dataLayer.push(data);
};

export const productImpressionEvent = (products, title) => {
  if (!products || Array.isArray(products) === false) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  return window.dataLayer.push({
    event: 'productImpression',
    ecommerce: {
      impressions: products.map((x, index) => {
        return {
          name: x.name,
          id: x.sku,
          price: x.prices.length>0 ? x.prices[0].amount : null,
          brand: x.brand,
          category: listCategories(x.categories),
          position: index + 1,
          list: title,
        };
      }),
    },
  });
};

export const checkoutEvent = (products, step) => {
  if ((!products || Array.isArray(products) === false) && !products.length) {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      currencyCode: analyticsCurrencyCode,
      checkout: {
        actionField: {
          step,
          description: '',
          option: '',
          action: 'checkout',
        },
        products: products.map(x => {
          return {
            name: x.product.name,
            id: x.product.sku,
            price: x.product.prices[0].amount,
            brand: x.product.brand,
            category: listCategories(x.product.categories),
            quantity: x.quantity,
          };
        }),
      },
    },
  });
};

export const checkoutOptionEvent = (step, option) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField: {
          step,
          option,
          action: 'checkout_option',
        },
      },
    },
  });
};

export const purchaseEvent = (cart, orderNumber, backendConfig) => {
  let totalTax = 0;
  if (!cart || !cart.items) {
    return;
  }
  // eslint-disable-next-line camelcase
  const {enterprise_id, action_id, tag_id, ccc_isActive, ccc_token, ccc_method} =
  (backendConfig && backendConfig.configProvider) || {};
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line no-return-assign
  cart.prices.applied_taxes.forEach((amount) => {
    totalTax += amount.amount.value
  })

  // Any cleanup we have to do (global variables)
  const cleanupPromise = new Promise(resolve => {

    // Remove enhanced conversions variables
    window.email = undefined;
    window.phoneNumber = undefined;

    resolve();
  });

  const purchasePromise = new Promise(resolve => {
    // Initialize the enhanced conversions context
    if (cart.email && cart.billing_address && cart.billing_address.telephone) {
      window.email = cart.email;
      window.phoneNumber = cart.billing_address.telephone;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ecommerce: null});

    let coupon_code = '';
    if (cart.applied_coupons && cart.applied_coupons.length > 0) {
      coupon_code = cart.applied_coupons[0].code;
    }

    const data = {
      event: 'purchase',
      ecommerce: {
        transaction_id: orderNumber,
        value: parseFloat(cart.prices.grand_total.value, 2),
        tax: parseFloat(totalTax, 2),
        shipping: parseFloat(cart.shipping_addresses[0].selected_shipping_method.price_incl_tax.value, 2),
        coupon: coupon_code,
        currency: analyticsCurrencyCode,
        items: cart.items.map((x, index) => {
          return {
            index: index,
            item_id: x.product.sku,
            item_name: x.product.name,
            price: parseFloat(x.prices.priceIncludingTax.value, 2),
            price_netto: parseFloat(x.prices.price.value, 2),
            tax: parseFloat((x.prices.priceIncludingTax.value - x.prices.price.value).toFixed(2)),
            item_brand: x.product.brand,
            item_category: typeof x.product.categories[0] !== 'undefined' ? x.product.categories[0].name : null,
            item_category2: typeof x.product.categories[1] !== 'undefined' ? x.product.categories[1].name : null,
            item_category3: typeof x.product.categories[2] !== 'undefined' ? x.product.categories[2].name : null,
            item_category4: typeof x.product.categories[3] !== 'undefined' ? x.product.categories[3].name : null,
            item_category5: typeof x.product.categories[4] !== 'undefined' ? x.product.categories[4].name : null,
            quantity: x.quantity,
          };
        }),
      },
    }
    window.dataLayer.push(data);
    resolve(true);
  });

  // const cjPromise = new Promise(async resolve => {
  //   if (getCookie('cje') && typeof window !== 'undefined') {
  //     if (!window.cj) window.cj = {};
  //     window.cj.order = {
  //       enterpriseId: enterprise_id,
  //       pageType: 'conversionConfirmation',
  //       emailHash: await sha256Encoder(cart.email.toLowerCase()),
  //       orderId: orderNumber,
  //       actionTrackerId: action_id,
  //       currency: analyticsCurrencyCode,
  //       amount: cart.prices.grand_total.value,
  //       coupon: cart.applied_coupons,
  //       cjeventOrder: getCookie('cje'),
  //       customerCountry: countryCode.slice(-2),
  //       customerStatus: 'New',
  //       taxAmount: totalTax,
  //       items: cart.items.map(item => ({
  //         itemId: item.product.sku,
  //         unitPrice: item.product.prices ? item.product.prices[0].amount : null,
  //         quantity: item.quantity,
  //       })),
  //     };
  //     window.cj.sitePage = {
  //       enterpriseId: enterprise_id,
  //       pageType: 'conversionConfirmation',
  //       emailHash: await sha256Encoder(cart.email.toLowerCase()),
  //       cartSubtotal: cart.prices.grand_total.value,
  //       items: cart.items.map(item => ({
  //         itemId: item.product.sku,
  //         unitPrice: item.product.prices ? item.product.prices[0].amount : null,
  //         quantity: item.quantity,
  //       })),
  //     };
  //   }
  //   resolve();
  // });

  // const initPromise = new Promise(resolve => {
  //   if(getCookie("cje") && tag_id){
  //     function cjInit(a,b,c,d){
  //       a=`https://www.mczbf.com/tags/${tag_id}/tag.js`;
  //       b=document;c='script';d=b.createElement(c);
  //       d.src=a;d.type='text/java'+c;d.async=true;d.id='cjapitag';
  //       a=b.getElementsByTagName(c)[0];
  //       a.parentNode.insertBefore(d,a)
  //     }
  //     cjInit();
  //   }
  //   resolve();
  // });

  const commercePromise = new Promise(resolve => {
    if (typeof window !== 'undefined') {
      if (window.CCConversionTag && ccc_isActive) {
        // init with your shopident token ("Same for HR and RS")
        window.CCConversionTag.init(ccc_token, ccc_method);
        // add all purchases
        cart.items.map(item => {
          return window.CCConversionTag.addPurchase(
            String(item.product.barcode),
            parseInt(item.quantity, 10),
            parseFloat(item.product.prices ? item.product.prices[0].amount : null),
          );
        });

        // fire conversion tag
        window.CCConversionTag.fire();
      }
    }
    resolve();
  });

  return Promise.all([purchasePromise, commercePromise, cleanupPromise])
      .catch(err => console.log(err));
};
export const productsInCatalogEvent = (products, pageData) => {
  if (!products || Array.isArray(products) === false) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  return window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      items: products.map((x, index) => {
        return {
          item_id: x.sku,
          item_name: x.name,
          currency: analyticsCurrencyCode,
          index: index,
          item_category: typeof x.categories[0] !== 'undefined' ? x.categories[0].name : null,
          item_category2: typeof x.categories[1] !== 'undefined' ? x.categories[1].name : null,
          item_category3: typeof x.categories[2] !== 'undefined' ? x.categories[2].name : null,
          item_category4: typeof x.categories[3] !== 'undefined' ? x.categories[3].name : null,
          item_category5: typeof x.categories[4] !== 'undefined' ? x.categories[4].name : null,
          item_list_id: pageData.name,
          item_list_name: pageData.name,

          price: x.prices.length > 0 ? parseFloat(x.prices[0].amount, 2) : null,
          quantity: 1
        };
      }),
    },
  });
};

export const selectProductEvent = (product) => {
  if (!product) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'select_item',
    ecommerce: {
      items: [
        {
          item_id: product.sku,
          item_name: product.name,
          index: 0,
          price: product.prices.length > 0 ? parseFloat(product.prices[0].amount, 2) : null,
          quantity: 1,
          currency: analyticsCurrencyCode,
        }
      ],
    },
  });
};

export const cartViewEvent = (cart) => {
  if ((!cart || Array.isArray(cart.items) === false) && !cart.items.length) {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  const data={
    event: 'view_cart',
    ecommerce: {
      currency: cart.prices.grand_total.currency,
      value: parseFloat(cart.prices.grand_total.value, 2),
      items: cart.items.map((x, index) => {
        return {
          item_id: x.product.sku,
          item_name: x.product.name,
          index,
          price: parseFloat(x.prices.priceIncludingTax.value, 2),
          item_brand: x.product.brand,
          item_category: typeof x.product.categories[0] !== 'undefined' ? x.product.categories[0].name : null,
          item_category2: typeof x.product.categories[1] !== 'undefined' ? x.product.categories[1].name : null,
          item_category3: typeof x.product.categories[2] !== 'undefined' ? x.product.categories[2].name : null,
          item_category4: typeof x.product.categories[3] !== 'undefined' ? x.product.categories[3].name : null,
          item_category5: typeof x.product.categories[4] !== 'undefined' ? x.product.categories[4].name : null,
          quantity: x.quantity,
        };
      }),
    },
  }
  window.dataLayer.push(data);
};

export const beginCheckoutEvent = (cart) => {
  if ((!cart.items || Array.isArray(cart.items) === false) && !cart.items.length) {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  const data={
    event: 'begin_checkout',
    ecommerce: {
      currency: cart.prices.grand_total.currency,
      value: parseFloat(cart.prices.grand_total.value, 2),
      items: cart.items.map((x, index) => {
        return {
          item_id: x.product.sku,
          item_name: x.product.name,
          currency: analyticsCurrencyCode,
          index: index,
          price: x.prices.priceIncludingTax.value,
          item_brand: x.product.brand,
          item_category: typeof x.product.categories[0] !== 'undefined' ? x.product.categories[0].name : null,
          item_category2: typeof x.product.categories[1] !== 'undefined' ? x.product.categories[1].name : null,
          item_category3: typeof x.product.categories[2] !== 'undefined' ? x.product.categories[2].name : null,
          item_category4: typeof x.product.categories[3] !== 'undefined' ? x.product.categories[3].name : null,
          item_category5: typeof x.product.categories[4] !== 'undefined' ? x.product.categories[4].name : null,
          quantity: x.quantity,
        };
      }),
    },
  }
  window.dataLayer.push(data);
};

export const addShippingInfoEvent = (products, shipping) => {
  if ((!products || Array.isArray(products) === false) && !products.length) {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'add_shipping_info',
    ecommerce: {
      currencyCode: analyticsCurrencyCode,
      shipping_tier: shipping,
      items: products.map((x, index) => {
        return {
          item_id: x.product.sku,
          item_name: x.product.name,
          currency: analyticsCurrencyCode,
          index: index,
          price: parseFloat(x.product.prices[0].amount, 2),
          item_brand: x.product.brand,
          quantity: x.quantity,
        };
      }),
    },
  });
};

export const addPaymentInfoEvent = (products, payment) => {
  if ((!products || Array.isArray(products) === false) && !products.length) {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'add_payment_info',
    ecommerce: {
      currencyCode: analyticsCurrencyCode,
      payment_type: payment,
      items: products.map((x, index) => {
        return {
          item_id: x.product.sku,
          item_name: x.product.name,
          currency: analyticsCurrencyCode,
          index: index,
          price: parseFloat(x.product.prices[0].amount, 2),
          item_brand: x.product.brand,
          quantity: x.quantity,
        };
      }),
    },
  });
};

export const addToWishListEvent = (sku, name, price) => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer.push({
    event: 'add_to_wishlist',
    ecommerce: {
      currency: analyticsCurrencyCode,
      items: [
        {
          item_id: sku,
          item_name: name,
          currency: analyticsCurrencyCode,
          index: 0,
          price: parseFloat(price, 2),
          quantity: 1,
        },
      ],
    },
  });
};
