import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { T } from '@deity-io/falcon-i18n';
import readConfig from '../../../utils/readClientConfiguration';
import productionConfiguration from '../../../../config/default.json';
import './ProductPromo.scss';

const dateFormat = readConfig(productionConfiguration, 'dateFormat');

const ProductPromoDescription = ({ promoPercentage, promotionStart, promotionEnd, promoText, type, isCms }) => {
  const prettyPromotionStart = moment(promotionStart).format(dateFormat);
  const prettyPromotionEnd = moment(promotionEnd).format(dateFormat);
  const mainClass = 'promo_description';

  return (
    <div
      className={clsx(
        'promo_description',
        isCms && 'promo_description-slider',
        type === 'promo' && `clearance-promo`,
        type === 'club' && 'promo_description__club',
      )}
    >
      <div className={clsx(`${mainClass}__primary`, isCms && `${mainClass}__primary-slider`)}>
        {type === 'club' && (
          <span className={`${mainClass}__primary-desc`}>
            <T id="club.clubPromoTooltip" />
            <br/>
            <b>
              <T id="club.clubPromoTooltipAction" />
            </b>
          </span>
        )}
        <br/>
        <span className={clsx(`${mainClass}__primary-desc`, type === 'club' && `${mainClass}__primary-desc_club`)}>
          {!promotionStart ? (
            <T id="product.promoDescriptionExpires" />
          ) : (
            <T id="product.promoDescription" promoStart={prettyPromotionStart} promoEnd={prettyPromotionEnd} />
          )}
        </span>
      </div>
    </div>
  );
};

export default ProductPromoDescription;
