import React, { memo } from 'react';
import clsx from 'clsx';

import './Button.scss';

const Button = ({ text, trait, type, className, children, ...props }) => {
  return (
    <button
      type={type}
      className={clsx(
        'btn',
        {
          btn_hover: trait === 'hover',
          'btn_hover-submit': trait === 'hover-submit',
          btn_facebook: trait === 'facebook',
          btn_google: trait === 'google',
          btn_profile: trait === 'profile',
          'btn_profile-hover': trait === 'profile-hover',
        },
        className,
      )}
      {...props}
    >
      <div className="btn__info">
        {trait === 'facebook' && (
          <img
            src="/images/fb-icon.png"
            className="btn__logo"
            alt="facebook_logo"
          />
        )}
        {trait === 'google' && (
          <img
            src="/images/goog-icon.png"
            className="btn__logo"
            alt="google_logo"
          />
        )}
        <span className="btn__text">{text}</span>
      </div>
      {children}
    </button>
  );
};

export default memo(Button);
