import React from 'react';
import LoaderAddProduct from '../../../assets/icons/loaderAddProduct.gif';
import HeartFillIcon from '../../../assets/img/heart-fill.svg';
import HeartIcon from '../../../assets/img/heart.svg';
import { useProduct } from '../../../context/ProductContext';
import useWishlist from '../../../hooks/useWishlist';

const AddToWishlist = () => {
  const { sku, name, prices } = useProduct();
  const { toggleInWishlist, loading, isInWishlist } = useWishlist(sku, name, null, prices.length > 0? prices[0].amount: null);

  return (
    <span href="#" className="product-item__favorite-link" onClick={toggleInWishlist}>
      <div className="product-item__favorite-icon-wrap">
        {!loading && <img loading="lazy" src={isInWishlist ? HeartFillIcon : HeartIcon} alt="" style={{ width: 25 }} />}
        {loading && <img loading="lazy" src={LoaderAddProduct} alt="" className="product-item__cart-icon" />}
      </div>
    </span>
  );
};

export default React.memo(AddToWishlist);
