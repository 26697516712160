import * as React from "react";
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import RangeSlider from '../RangeSlider';

class FilterItem extends React.Component {
    constructor(props) {
        super(props);
        const options = this.props.aggregation.options;
        this.attribute_code = this.props.aggregation.attribute_code;
        this.label = this.props.aggregation.label;
        this.type = this.props.type;
        this.minPrice = Math.min.apply(Math, options.map(({value}) => value));
        this.maxPrice = Math.max.apply(Math, options.map(({value}) => value));
        // Store all items and displayed items, both will be initialized with same data
        this.state = {
            items: [...options]
        }
    }

    render() {
        // If we have products with the same price
        if(this.minPrice === this.maxPrice) {
            return <></>
        }
        const initialData = this.props.initialState.find(x => x.key === this.attribute_code);

        return (<>
            <AccordionItem uuid={this.attribute_code} key={this.attribute_code}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {this.label}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <RangeSlider
                        initialData={initialData ? initialData.value.split("-") : [this.minPrice, this.maxPrice]}
                        attribute_code={this.attribute_code}
                        label={this.label}
                        minValue={this.minPrice}
                        maxValue={this.maxPrice}
                        options={this.state.items}
                        setUrlParam={this.props.setUrlParam}
                    />
                </AccordionItemPanel>
            </AccordionItem>
        </>)
    }
}

export default FilterItem;