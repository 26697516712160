import React from "react";
import './ProductPrice.scss';
import {useQuery} from "@apollo/react-hooks";
import {BASE_CURRENCY} from "../../../commonQueries/getBackendConfig";
import {priceNumberFormatter} from "../../../utils/price";
import {currencySymbol} from "../../../utils/formatLocalePrice";
import readConfig from "../../../utils/readClientConfiguration";
import productionConfiguration from "../../../../config/default.json";
import PriceLabel from "./PriceLabel";

const ProductPrice = ({price, hideLabels = false, hidePrice = false, description = null, shortLabel=false}) => {
    const baseCurrency = readConfig(productionConfiguration, 'currencyCode');

    const {data} = useQuery(BASE_CURRENCY);
    const {available_currency_codes: currencies, exchange_rates: rates} =
    (data && data.backendConfig && data.backendConfig.shop.currency) || {};

    const calculatedAmounts =
        currencies &&
        currencies.map(currency => {
            return {
                price: rates.find(x => x.currency_to === currency).rate * price.amount,
                currency,
            };
        });
    const notBaseAmount = calculatedAmounts && calculatedAmounts.find(x => x.currency !== baseCurrency);
    if (!price.currency_symbol) {
        price.currency_symbol = currencySymbol(baseCurrency);
    }

    const drawUnit = (unit) => {
        if(unit==='m2') {
            return (
                <span> / m<sup>2</sup></span>
            )
        }
        return (
            <span> / {unit}</span>
        )
    }
    return (
        <div
            key={price.price_type}
            className={[
                'base-price',
                price.price_type].join(' ')}
        >
            <span className={'price-wrapper'}
                // style={{color:price.bg_color}}
            >
                {description && <span class={'price-description'}>{description}</span>}
                {!hidePrice &&
                    <span
                        className={'base-currency-price'}>
                        {priceNumberFormatter(price.amount,baseCurrency)}
                        {price.unit && drawUnit(price.unit)}
                    </span>
                }

                {!hidePrice &&
                    notBaseAmount && <span
                        className={'not-base-currency-price'}>{priceNumberFormatter(notBaseAmount.price, notBaseAmount.currency)}
                        {price.unit && drawUnit(price.unit)}
                </span>
                
                }

        </span>
            {!hideLabels && <PriceLabel price={price} shortLabel={shortLabel}/>}
        </div>
    )
}

export default ProductPrice