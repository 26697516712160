import {T} from '@deity-io/falcon-i18n';
import React, {useEffect, useRef, useState} from 'react';
import {useListingContext,} from '../../context/ListingContext';
import ListingProducts from "../../components/ListingProducts/ListingProducts";
import '../Category/Category.scss';
import {useQueryListing} from "../../hooks/useQueryListing";
import {useLocation} from "react-router-dom";
import Paginator from "../../components/ListingProducts/Paginator";
import ProductFilters from "../../components/ProductFilters/ProductFilters";
import EmptyListing from "../../components/EmptyListing/EmptyListing";

const SearchResults = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const queryString = searchParams.getAll('q').join(' ');
    const [searchString, setSearchString] = useState();
    const {buildListingParamsFromQuery} = useQueryListing()
    const {
        resetListing,
        listingParams,
        setInitialListingParams,
        initListing,
        setListingParams,
        listingData,
        products
    } = useListingContext();

    const {totalPages=0}=Object.assign({},{totalPages:0},listingData)
    const myRef=useRef(null)
    const params = [
        {
            key: 'filters',
            value: [],
        }
    ];
    let showVerticalFilters = true
    let showHorizontalFilters = !showVerticalFilters

    useEffect(() => {
        if (queryString) {
            setSearchString(queryString)
        }
    }, [queryString])


    useEffect(() => {
        if (searchString) {
            resetListing(params);
            const _params = buildListingParamsFromQuery(
                params
            );
            setListingParams(initListing(_params))

        }
    }, [searchString])

    const filterHandle = (newFilters) => {
        listingParams.currentPage = 1;
        listingParams.filters = newFilters;
        setListingParams({...listingParams});
    }

    const isDyson = searchString && searchString.toLowerCase() == 'dyson';


    if (products.length == 0) {
        return (
            <EmptyListing/>
        );
    }

    return (
        <>
            <div className="category">
                <h1 className="search-results-title">
                    <T id="category.searchResultsFor"/>: {searchString}
                </h1>
                {isDyson && <div>
                    <a className="dyson-brand-store-banner-link" href="/dyson-demozone-new">Dyson brand store</a>
                </div>}
                <div className="columns" ref={myRef} >
                    {products.length > 0 && showVerticalFilters && <div className="columns__left">
                        <ProductFilters
                            filterHandle={filterHandle}
                        />
                    </div>}
                    <div className='columns__right'>
                <ListingProducts filterHandle={filterHandle}/>
                    </div>
                </div>

                {totalPages > 1 &&
                    <Paginator handle={filterHandle}/>}
            </div>
        </>
    );

}

export default SearchResults
