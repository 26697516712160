import {I18nContext, T} from '@deity-io/falcon-i18n';
import clsx from 'clsx';
import {bool, number, string} from 'prop-types';
import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {useUserContext} from '../../../context/UserContext';
import emmezetaPlaceholder from '../../../assets/img/emmezeta-placeholder.png';
import loaderAddProduct from '../../../assets/icons/loaderAddProduct.gif';
import CartOutOfStockIcon from '../../../assets/img/cart-out-of-stock.webp';
import CartIcon from '../../../assets/img/cart.webp';
import CartOnlyInStore from '../../../assets/img/OnlyInStore.png';
import {useCartContext} from '../../../context/CartContext';
import {usePopup} from '../../../context/PopupContext';
import {addToCartEvent} from '../../../hooks/useAnalytics';
import useMountState from '../../../hooks/useMountState';
import ProductPromo from '../../UI/ProductPromo/ProductPromo';
import ChooseLink from './ChooseLink';
import AddToWishlist from './AddToWishlist';
import './ProductItem.scss';
import Labels from "../../Product/Labels/Labels";
import PriceBlock from "../../Product/PriceBlock/PriceBlock";
import {ProductProvider} from "../../../context/ProductContext";
import {BuildAbsoluteUrl} from "../../../utils/urlBuilder";
import useResponsive from "../../../hooks/useResponsive";
import AddToCompare from "../../Compare/AddToCompare";

const baseClassName = 'product-item';

const AddToCartButton = ({product}) => {
    if (product === null || product === undefined || product.sku === undefined) {
        return <></>;
    }



    const {sku, name, isOnOrder, isHasStock, days_to_delivery, canonical_url, advanced_product_inventory} = product;
    // TODO: read product type instead of matching by SKU format:
    const isGrouped = product.sku.startsWith('g');
    const {user} = useUserContext();
    const {isMounted} = useMountState();
    const {handleProductAdd} = useCartContext();
    const [addProductLoading, setAddProductLoading] = useState(false);
    const {t} = useContext(I18nContext);
    const {showAddToCartPopupMessage} = usePopup();
    let productUrl = typeof canonical_url !== 'undefined'? BuildAbsoluteUrl(canonical_url) : null;
    let enableQtyIncrements = '0';
    let qtyIncrements = 0;
    if (typeof advanced_product_inventory !== 'undefined' && advanced_product_inventory.hasOwnProperty('enable_qty_increments')) {
        enableQtyIncrements = advanced_product_inventory.enable_qty_increments;
        qtyIncrements = advanced_product_inventory.qty_increments;
    }

    const addProductToCart = () => {
        setAddProductLoading(true);
        let qty = 1;
        if (enableQtyIncrements === '1') {
            qty = Number(qtyIncrements);
        }
        handleProductAdd(sku, qty)
            .then(() => {
                const message = t('messagePopup.addSuccess').replace('[product]', name);
                showAddToCartPopupMessage(message, 'success', 4000);
                addToCartEvent(product, 1, productUrl);
            }, err => {
                if (err.message.includes('requested qty')) {
                    showAddToCartPopupMessage(t('messagePopup.qtyError'), 'error', 4000);
                } else {
                    showAddToCartPopupMessage(t(err.message), 'error', 4000);
                }
            },)
            .finally(() => {
                if (isMounted()) {
                    setAddProductLoading(false);
                }
            });
    };

    if (isOnOrder && days_to_delivery === 0) {
        return (<button type="button" className={`${baseClassName}__cart-button`} name="Add to cart">
            <img src={CartOnlyInStore} alt="" className={`${baseClassName}__cart-icon`}/>
        </button>);
    }

    if (!isHasStock) {
        return (<button type="button" className={`${baseClassName}__cart-button`} name="Add to cart">
            <img src={CartOutOfStockIcon} alt="" className={`${baseClassName}__cart-icon`}/>
        </button>);
    }

    const addToCartButton = (<button
        type="button"
        className={`${baseClassName}__cart-button`}
        onClick={addProductToCart}
        disabled={addProductLoading}
        name="Add to cart"
    >
        {addProductLoading || <img src={CartIcon} alt="" className={`${baseClassName}__cart-icon`}/>}
        {addProductLoading && <img src={loaderAddProduct} alt="" className={`${baseClassName}__cart-icon`}/>}
    </button>);

    return isGrouped ? <Link to={`/${canonical_url}`}>{addToCartButton}</Link> : addToCartButton;
};

const ProductItem = ({product = {}, isCms, isCategory, isQuarticon}) => {
    const {isMobile} = useResponsive();
    const {
        name,
        image,
        thumbnailImage,
        hoverImage,
        sku,
        lifetimeStatus,
        isOnOrder,
        guarantees,
        url,
        canonical_url
    } = product;

    let productImage = image;
    if (!productImage) {
        productImage = emmezetaPlaceholder;
    }

    const {days_to_delivery} = product;
    const linkUrl = canonical_url || url;

    let hideAddToCart = false;

    switch (product.__typename) {
        case "GroupedProduct":
            hideAddToCart = true;
            break;
    }
    return (
        <li key={product.sku} className={clsx(`${baseClassName}`, isCms && `${baseClassName}__mr-auto`)}>
            <ProductProvider value={product} key={['sss', product.sku].join('__')}>
                <div className={`${baseClassName}__wrap`}>

                    <div className={`${baseClassName}__top-line`}>
                        <div className={`${baseClassName}__top-line__container`}>

                            <Labels product={product} slot={'TL'} type={'category'}/>
                            {!!isOnOrder && days_to_delivery === 0 && (<>
                                <div className={`${baseClassName}__order-product`}>
                                    <ProductPromo type="order" promoText={<T id="product.orderInTheStore"/>}/>
                                </div>
                                <div className={`${baseClassName}__order-tooltip`}>
                                    <T id="product.orderInTheStoreText"/>
                                </div>
                            </>)}


                        </div>
                        <div className={`${baseClassName}__top-line__container_bottom`}>
                            {guarantees && guarantees.length ? (
                                <div className={`${baseClassName}__top-line__guarantees-container`}>
                                    {guarantees.map(({guarantee_img_url, guarantee_description}) => (<>
                                        {guarantee_img_url && (<div className={`${baseClassName}__guarantee`}
                                                                    key={guarantee_img_url}>
                                            <img loading="lazy" src={guarantee_img_url} alt="guarantee"
                                                 style={{width: 45}}/>
                                        </div>)}
                                        {guarantee_description && (<div
                                            className={`${baseClassName}__guarantee-description`}>{guarantee_description}</div>)}
                                    </>))}
                                </div>) : (<></>)}
                        </div>
                        <ChooseLink link={linkUrl} isQuarticon={isQuarticon} product={product}>
                            <div className={`${baseClassName}__img-wrap`}>
                                <img
                                    src={thumbnailImage.url}
                                    className={clsx(`${baseClassName}__image`, hoverImage && 'on_hover')}
                                    alt={name}
                                    loading="lazy"
                                />
                                {hoverImage && (<img
                                    src={hoverImage.url}
                                    className={clsx(`${baseClassName}__hover-image`)}
                                    alt={name}
                                    loading="lazy"
                                />)}
                            </div>
                        </ChooseLink>

                        <AddToCompare/>
                        <AddToWishlist/>

                    </div>

                    <div className={`${baseClassName}__center-line`}>
                        <ChooseLink link={linkUrl} product={product}>
                            <div
                                className={clsx(`${baseClassName}__details`, isCms && `${baseClassName}__details-slider`)}>
                                <strong className={`${baseClassName}__name`}>
                                    {name}
                                </strong>
                                {sku && (<div className={`${baseClassName}__sku ellipsis`}>
                                  <span>
                                    <T id="productItem.sku"/>
                                  </span>
                                    <span> {sku}</span>
                                </div>)}

                                <PriceBlock product={product} shortLabel={isMobile}/>

                            </div>
                        </ChooseLink>
                    </div>
                </div>


                {!hideAddToCart &&
                    <div className={`${baseClassName}__bottom-line`}>
                        <div
                            className={clsx(`${baseClassName}__cart-icon-wrap`, isCms && `${baseClassName}__cart-icon-wrap-slider`)}>
                            <AddToCartButton product={product}/>
                        </div>
                    </div>
                }
            </ProductProvider>
        </li>
    );
};

ProductItem.prototype = {
    name: string,
    canonical_url: string,
    prices: Object,
    oldPrice: number,
    promoPrice: number,
    loyaltyPrice: number,
    image: string,
    hoverImage: string,
    sku: string,
    isHasStock: bool,
    lifetimeStatus: string,
    isOnOrder: bool,
};

export default React.memo(ProductItem);
