import React from 'react';
import './Labels.scss';


const LabelHref = (props) => {
    const {children, label} = props;
    const {href} = label;

    function goToUrl(url, event) {
        event.preventDefault()
        window.open(url, '_blank');

        return;
    }


    if (href) {
        return (
            <a target='_blank' onClick={(e) => goToUrl(href, e)}>{children}</a>
        )
    }

    return (
        <>
            {children}
        </>
    )
}

const Labels = ({type, slot, product, children}) => {
    const {emlabels=[], lifetime_status} = product;
    const labels = emlabels.filter((label) => {
        return label[[type, 'position'].join('_')] == slot;
    });

    function getStyle(label) {
        let style = {};
        if (label.label_image) {
            style['backgroundImage'] = 'url(' + label.label_image + ')'
        }
        return style;
    }

    return (
        <ul className={['labels', slot, type].join(' ')}>
            {
                labels.map((label, index) => {
                        return (
                            <li key={index}>
                                <LabelHref label={label} >
                                    {label.label_text && <span className='label'>{label.label_text}</span>}
                                    <div style={getStyle(label)} />
                                </LabelHref>
                            </li>
                        )
                    }
                )
            }
            {children}
        </ul>
    )
}

export default Labels;