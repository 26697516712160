import React, {useEffect, useRef, useState} from 'react';
import {useCompare} from "../../hooks/useCompare";
import {COMPARE_QUERY} from "./Query";
import {useMutation} from "react-apollo";
import {normalizeProductItem} from "../Category/Products/Products";
import ProductItem from "../Category/ProductItem/ProductItem";
import CompareRow from "./CompareRow";
import {T} from "@deity-io/falcon-i18n";

const _defaultCompareData = {
    attributes: [],
    item_count: 0,
    items: []
}
const Compare = () => {
    const {compareProducts, removeCompare} = useCompare();
    const [compareData, setCompareData] = useState(_defaultCompareData)

    const [compareMutation, {data, loading, error}] = useMutation(COMPARE_QUERY);

    const headerRef = useRef(null);


    useEffect(() => {
            compareMutation(
                {
                    variables: {
                        input: {
                            products: compareProducts.map((product) => product.id)
                        }
                    }
                }
            ).then((res) => {
                const {attributes = [], item_count = 0} = res.data.createCompareList
                const items = res.data.createCompareList.items.map((item) => {
                    return Object.assign({},
                        item,
                        {
                            product: normalizeProductItem(item.product)
                        }
                    )
                })
                setCompareData(Object.assign({}, _defaultCompareData, {
                    attributes,
                    item_count,
                    items
                }))
            })
        },
        [compareProducts]
    )


    let headClasses = ['head']

    if(!loading && compareData.items.length==0){
        return (
            <h1><T id="compareNoProducts"/></h1>
        )
    }

    return (
        <div id='compare-products-wrapper'>
            <td id='compare-products-table'>
                <div ref={headerRef} className={headClasses.join(' ')}>
                    <div className='item-row'>
                        <div className='info'></div>
                        {
                            compareData.items.map((item) => {
                                    return (
                                        <div className='item'><ProductItem key={item.product.id} product={item.product}/>
                                        </div>
                                    )
                                }
                            )
                        }
                        {
                            compareData.items.length == 1
                            && <div className='item minimumItemsInfo'>
                                <T id="MinimumCompareProducts"/>
                            </div>
                        }
                    </div>
                </div>
                <div className='body'>
                    {
                        compareData.attributes.map((attribute, index) => {
                            return (<CompareRow attribute={{...attribute, index: index}} items={compareData.items}/>)
                        })
                    }
                </div>
            </td>

        </div>
    )

}

export default Compare