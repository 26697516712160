import { ADD_PRODUCT_TO_CART } from '../queries/cart';
import useCartMutation from './useCartMutation';

export const useAddProductToCart = () => {
  const [addProduct, addProductResult] = useCartMutation(ADD_PRODUCT_TO_CART);

  const addProductToCart = (sku, quantity) => {
    return addProduct({
      variables: {
        cart_items: {
          data: {
            sku,
            quantity,
          },
        },
      },
    });
  };

  const addProductsToCart = products => {
    return addProduct({
      variables: {
        cart_items: products.map(product => ({
          data: {
            sku: product.sku,
            quantity: product.quantity,
          },
        })),
      },
    });
  };

  return Object.assign([addProductToCart, addProductResult], {
    addProductsToCart,
    addProductResult,
  });
};
