import React from 'react';
import readConfig from "./readClientConfiguration";
import productionConfiguration from "../../config/default.json";

const buildAbsoluteUrl = (path) => {
    if(!path){
        return '/'
    }
    const base_url = readConfig(productionConfiguration, 'base_url');

    if (!path.startsWith('/')) {
        path = '/'+path;
    }
    if (base_url) {
        return `${base_url}${path}`;
    }
    return path;
}

export const BuildAbsoluteUrl = buildAbsoluteUrl