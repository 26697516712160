import React from 'react';
import clsx from 'clsx';
import productionConfiguration from '../../../../config/default.json';
import readConfig from '../../../utils/readClientConfiguration';
import InfoIconWhite from '../../../../public/images/info-icon-white.svg';
import InfoIconBlack from '../../../../public/images/info-icon-black.svg';
import ProductPromoDescription from './ProductPromoDescription';
import './ProductPromo.scss';

const baseClassName = 'product-promo';
const countryCode = readConfig(productionConfiguration, 'countryCode');

const ProductPromo = ({
  type = 'primary',
  promoText = 'Akcija',
  promoPercentage = '',
  isProductPage = false,
  isSearch,
  isDropdown,
  txt_color,
  bg_color,
  promotionStart,
  promotionEnd,
  isGrouped,
  price_last_30,
  amount,
  isPriceLabel,
  inHeader,
  isCms,
  isCategory,
  priceType,
}) => {
  const isClearance = priceType === 'simple_clearance';
  const last30IsSmaller = price_last_30 < amount;
  const showOnlyText = isClearance && last30IsSmaller;
  const noMarginTop = countryCode !== 'hr-HR' && isClearance && (isProductPage || isSearch || isCategory);

  if (countryCode === 'hr-HR' && price_last_30 && price_last_30 - amount > 0 && type !== 'club') {

    if (promoPercentage < 1) {
      return null;
    }
  }

  const willInfoImageShow = !showOnlyText && !isProductPage && !isSearch && !isDropdown && isPriceLabel && !inHeader;

  return (
    <div
      className={clsx(
        `${baseClassName}__price-banner ${baseClassName}__price-banner--${type}`,
        isPriceLabel && 'banner-price-label',
        noMarginTop && `${baseClassName}__no-margin-top`,
      )}
    >
      {promoText.length !== 0 && (
        <span
          style={{
            whiteSpace: 'nowrap',
            color: txt_color && txt_color,
            background: type !== 'primary' && bg_color && bg_color,
          }}
          className={clsx(
            `${baseClassName}__price-banner-text ${baseClassName}__price-banner-text--${type}`,
            !isSearch && `${baseClassName}__price-banner-text__width-${type}`,
            isProductPage && `${baseClassName}__price-banner-text_product`,
            isSearch && `${baseClassName}__price-banner-text_search`,
            isDropdown && `${baseClassName}__price-banner-text_dropdown`,
            countryCode !== 'hr-HR' && `${baseClassName}__price-banner-text_uppercase`,
          )}
        >
          {countryCode === '1hr-HR' && !isGrouped && isPriceLabel ? (
            <div
              className={clsx(
                `${baseClassName}__price-banner-description`,
                isDropdown && `${baseClassName}__price-banner-description_dropdown`,
              )}
            >
              <div className={`${baseClassName}__price-banner-description_info-text`}>
                {!showOnlyText && <>-{promoPercentage}% </>}
                {promoText}
              </div>
              {willInfoImageShow && (
                <>
                  <img
                    className={`${baseClassName}__price-banner-description_icon`}
                    src={type === 'promo' ? InfoIconBlack : InfoIconWhite}
                    alt="promo-info"
                  />
                  <ProductPromoDescription
                    promoPercentage={promoPercentage}
                    promoText={promoText}
                    promotionStart={promotionStart}
                    promotionEnd={promotionEnd}
                    type={type}
                    isCms={isCms}
                  />
                </>
              )}
            </div>
          ) : (
            <div
              className={
                `${baseClassName}__price-banner-description_info-text`
              }
            >
              <span>{promoText}</span>
              {promoPercentage.length !== 0 && <span> -{promoPercentage}%</span>}
            </div>
          )}
        </span>
      )}
    </div>
  );
};

export default ProductPromo;
