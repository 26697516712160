import gql from 'graphql-tag';


const MinimalProductFields = gql`
    fragment MinimalProductFields on ProductInterface {
        name
        sku
        uid
        id
        meta_title
        meta_description
        meta_keyword
        product_declaration
        emlabels {
                label_text
              attribute_code
              priority
              product_position
              category_position
              label_image
              href
            }
        points_coordinates{
            left
            top
            sku
        }
        hide_quarticon
        guarantees {
            guarantee_description
            guarantee_img_url
        }
        advanced_product_inventory {
            __typename
            is_qty_decimal
            use_config_enable_qty_inc
            enable_qty_increments
            use_config_qty_increments
            qty_increments
            use_config_min_sale_qty
            min_sale_qty
            unit
        }
        vendor_id
        days_to_delivery
        days_to_delivery_range {
            from
            to
        }
        is_on_order_only
        lifetime_status
        brand
        barcode
        is_saleable
        canonical_url
        categories {
            __typename
            name
            canonical_url
            breadcrumbs {
                category_id
                category_name
                category_level
                category_url
                category_url_key
            }
        }
        swatch_attribute_images {
            code
            img
        }
        special_price
        price_last_30
        price_range{
          minimum_price{
              final_price{
                  currency
                  value
              }
          }
          maximum_price{
              final_price{
                  currency
                  value
              }
          }
        }
        prices {
            amount
            unit
            bg_color
            currency_symbol
            label
            description
            old_amount
            percentage
            price_type
            promotion_start
            promotion_end
            txt_color
        }
        gallery {
            disabled
            disabled_default
            id
            label
            label_default
            large_image_url
            media_type
            medium_image_url
            hover_image_url
            position
            position_default
            small_image_url
            url
            is_hover
            is_thumbnail
            is_inspiration
        }
        ... on GroupedProduct {
            items {
                product {
                    price_last_30
                     advanced_product_inventory{
                        unit
                        enable_qty_increments
                    }
                    categories {
                        __typename
                        name
                        canonical_url
                        breadcrumbs {
                            category_id
                            category_name
                            category_level
                            category_url
                            category_url_key
                        }
                    }
                    gallery {
                      disabled
                      disabled_default
                      file
                      id
                      label
                      label_default
                      large_image_url
                      media_type
                      medium_image_url
                      position
                      position_default
                      row_id
                      small_image_url
                      url
                      value_id
                        is_hover
                        is_thumbnail
                    }
                    
                    prices {
                        price_type
                        amount
                        bg_color
                        currency_symbol
                        label
                        unit
                        old_amount
                        percentage
                        price_type
                        promotion_start
                        promotion_end
                        txt_color
                        description
                    }
                }
            }
        }
    }
`;

export const ProductFields = gql`
    fragment ProductFields on ProductInterface {
        __typename
        sku
        id
        page_layout
        points_coordinates {
            left
            top
            sku
        }
        url_key
        canonical_url
        name
        is_saleable
        emlabels {
            attribute_code
            priority
            product_position
            category_position
            label_image
            href
        }
        gallery {
            disabled
            disabled_default
            id
            label
            label_default
            large_image_url
            media_type
            medium_image_url
            hover_image_url
            position
            position_default
            small_image_url
            url
            is_hover
            is_thumbnail
            is_inspiration
        }
        special_price
        price_last_30
        prices {
            amount
            unit
            bg_color
            currency_symbol
            label
            description
            old_amount
            percentage
            price_type
            promotion_end
            promotion_start
            txt_color
        }
        storefront_attributes {
            declarationAttributes {
                code
                label
                title
                value
            }
            dimensionAttributes {
                code
                label
                title
                value
            }
            otherAttributes {
                code
                label
                title
                value
            }
            packagingAttributes {
                code
                label
                title
                value
            }
        }
        attachments {
            attachment_type
            created_at
            customer_groups
            customer_groups_use_defaults
            extension
            file_id #old
            filename
            filename_use_defaults
            filepath #old
            frontend_url
            icon
            icon_url
            is_visible
            label
            link #old
            mime_type
            show_file_id
            size #old
            updated_at
            url_hash
        }
        description {
            html
        }
        short_description {
            html
        }
        related_products {
            __typename
            sku
        }
        upsell_products {
            __typename
            sku
        }
        inspirations {
            name
            sku
            thumbnail {
                url
                label
            }
            url_key
            canonical_url
            gallery {
                disabled
                disabled_default
                file
                id
                label
                label_default
                media_type
                medium_image_url
                position
                position_default
                row_id
                small_image_url
                url
                value_id
            }
            is_saleable
            price_last_30
            prices {
                amount
                unit
                bg_color
                currency_symbol
                label
                description
                old_amount
                percentage
                price_type
                promotion_start
                promotion_end
                txt_color
            }
        }
    }
`

export const INTROSPECTION_QUERY = gql`
    query introspection {
        __schema {
            queryType {
                fields {
                    name
                    args {
                        name
                        type {
                            inputFields {
                                name
                                type {
                                    kind
                                    name
                                }
                            }
                            kind
                            name
                        }
                    }
                }
            }
        }
    }
`

export const POINTS_COORDINATES = gql`
    mutation($sku: String!, $points_coordinates: String!) {
        setPointsCoordinates(sku: $sku, points_coordinates: $points_coordinates) {
            __typename
           
        }
    }
`


export const SEARCH_NAVIGATION = gql`
    query getProducts(
        $filter: ProductAttributeFilterInput
        $search: String
        $sort: ProductAttributeSortInput
        $pageSize: Int = 12
        $currentPage: Int = 1
    ) {
        __schema {
            queryType {
                fields {
                    name
                    args {
                        name
                        type {
                            inputFields {
                                name
                                type {
                                    kind
                                    name
                                }
                            }
                            kind
                            name
                        }
                    }
                }
            }
        }

        products(filter: $filter, search: $search, sort: $sort, pageSize: $pageSize, currentPage: $currentPage) {
            page_info {
                current_page
                total_pages
                page_size
            }
            sort_fields {
                default
                options {
                    label
                    value
                }
            }
            aggregations {
                attribute_code
                frontend_input
                count
                label
                has_more
                options {
                    count
                    label
                    value
                }
                position
                rel_nofollow
            }
            items {
                __typename
                uid
                id
                sku
                name
                brand
                advanced_product_inventory {
                    __typename
                    is_qty_decimal
                    use_config_enable_qty_inc
                    enable_qty_increments
                    use_config_qty_increments
                    qty_increments
                    use_config_min_sale_qty
                    min_sale_qty
                    unit
                }
                categories {
                    __typename
                    name
                }
                is_saleable
                lifetime_status
                is_on_order_only
                guarantees {
                    guarantee_description
                    guarantee_img_url
                }
                gallery {
                    disabled
                    disabled_default
                    file
                    id
                    label
                    label_default
                    media_type
                    medium_image_url
                    hover_image_url
                    position
                    position_default
                    row_id
                    small_image_url
                    url
                    value_id
                    is_hover
                    is_thumbnail
                    is_inspiration
                }
                canonical_url
                url_key
                 emlabels {
                     label_text
                      attribute_code
                      priority
                      product_position
                      category_position
                      label_image
                      href
                    }
                hide_quarticon
                special_price
                price_last_30
                prices {
                    amount
                    unit
                    bg_color
                    currency_symbol
                    label
                    description
                    old_amount
                    percentage
                    price_type
                    promotion_end
                    promotion_start
                    txt_color
                }
                ... on GroupedProduct {
                    items {
                        product {
                            price_last_30
                            advanced_product_inventory{
                        unit
                        enable_qty_increments
                    }
                    gallery {
              disabled
              disabled_default
              file
              id
              label
              label_default
              large_image_url
              media_type
              medium_image_url
              position
              position_default
              row_id
              small_image_url
              url
              value_id
            }
                prices {
                    price_type
                    amount
                    bg_color
                    currency_symbol
                    label
                    unit
                    old_amount
                    percentage
                    price_type
                    promotion_start
                    promotion_end
                    txt_color
                        description
                }
                        }
                    }
                }
            }
        }
    }
`;

const ROUTE_NAVIGATION = gql`
    ${ProductFields}
    ${MinimalProductFields}
    query ($url: String! $filter: ProductAttributeFilterInput $sort: ProductAttributeSortInput $pageSize: Int $currentPage: Int) {
        route(url: $url, filter: $filter, sort: $sort, pageSize: $pageSize, currentPage: $currentPage) {
            type
            __typename
            ...MinimalProductFields
            ...ProductFields
            ... on CmsPage {
                __typename
                title
                url_key
                content_json
                hydrated_content
                identifier
                page_layout
                open_graph {
                    title
                    description
                    image
                }
            }
            ... on CategoryTree {
                __typename
                uid
                name
                canonical_url
                meta_title
                meta_keywords
                meta_description
                is_virtual_category
                subcategory_items_list
                category_description
                page_layout
                category_description_bottom
                hide_quarticon
                breadcrumbs {
                    __typename
                    category_level
                    category_name
                    category_uid
                    category_url
                    category_url_key
                    category_url_path
                }
                children_count
                children {
                    __typename
                    uid
                    name
                    canonical_url
                    image
                    meta_title
                    current_store_paths {
                        __typename
                        base_url_image
                    }
                }
                current_store_paths {
                    __typename
                    base_url_image
                }
                productsWithFilters(filter: $filter, sort: $sort, pageSize: $pageSize, currentPage: $currentPage) {
                    total_count
                    page_info {
                        current_page
                        total_pages
                        page_size
                    }
                    aggregations {
                        attribute_code
                        frontend_input
                        count
                        label
                        has_more
                        options {
                            count
                            label
                            value
                        }
                        position
                        rel_nofollow
                    }

                    items {...MinimalProductFields}
                }

            }

            ... on GroupedProduct {
                items {
                    position
                    qty
                    product {
                        __typename
                        sku
                        canonical_url
                        name
                        categories {
                            __typename
                            name
                            canonical_url
                            breadcrumbs {
                                category_id
                                category_name
                                category_level
                                category_url
                                category_url_key
                            }
                        }
                        gallery {
                            disabled
                            disabled_default
                            file
                            id
                            label
                            label_default
                            large_image_url
                            media_type
                            medium_image_url
                            position
                            position_default
                            row_id
                            small_image_url
                            url
                            value_id
                            is_hover
                            is_thumbnail
                        }
                        advanced_product_inventory {
                            __typename
                            is_qty_decimal
                            use_config_enable_qty_inc
                            enable_qty_increments
                            use_config_qty_increments
                            qty_increments
                            use_config_min_sale_qty
                            min_sale_qty
                            unit
                        }
                        uid
                        is_saleable
                        special_price
                        price_last_30
                        gallery {
                            disabled
                            disabled_default
                            file
                            id
                            label
                            label_default
                            large_image_url
                            media_type
                            medium_image_url
                            position
                            position_default
                            row_id
                            small_image_url
                            url
                            value_id
                        }
                        price_range{
                            minimum_price{
                                final_price{
                                    currency
                                    value
                                }
                            }
                            maximum_price{
                                final_price{
                                    currency
                                    value
                                }
                            }
                        }
                        prices {
                            amount
                            unit
                            bg_color
                            currency_symbol
                            label
                            description
                            old_amount
                            percentage
                            price_type
                            promotion_start
                            promotion_end
                            txt_color
                        }
                        price {
                            regularPrice {
                                amount {
                                    value
                                    currency
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default ROUTE_NAVIGATION;

export const LISTING_PRODUCTS_FRAGMENT = gql`
    fragment ProductInfo on ProductInterface{

        uid
        sku
        id
        name
        categories{
            name
        }
        gallery {
            disabled
            disabled_default
            file
            id
            label
            label_default
            large_image_url
            media_type
            medium_image_url
            hover_image_url
            position
            position_default
            row_id
            small_image_url
            url
            value_id
            is_hover
            is_thumbnail
            is_inspiration
        }
        canonical_url
        guarantees {
            guarantee_description
            guarantee_img_url
        }
        url_key
        emlabels {
            attribute_code
            priority
            product_position
            category_position
            label_image
            href
        }
        hide_quarticon
        lifetime_status
        is_saleable
        days_to_delivery
        special_price
        price_last_30
        advanced_product_inventory {
            __typename
            is_qty_decimal
            use_config_enable_qty_inc
            enable_qty_increments
            use_config_qty_increments
            qty_increments
            use_config_min_sale_qty
            min_sale_qty
            unit
        }
        prices {
            amount
            unit
            bg_color
            currency_symbol
            label
            description
            old_amount
            percentage
            price_type
            promotion_end
            promotion_start
            txt_color
        }
    }
`;

export const AGGREGATIONS_FRAGMENT = gql`
    fragment Aggregation on Products{
        aggregations {
            attribute_code
            frontend_input
            count
            label
            has_more
            options {
                count
                label
                value
            }
            position
            rel_nofollow
        }
    }
`;

export const PRODUCT_LISTING=gql`
    query products($filter: ProductAttributeFilterInput $pageSize: Int $sort: ProductAttributeSortInput $currentPage: Int $search: String) {
        products(filter: $filter, pageSize: $pageSize, sort: $sort, currentPage: $currentPage, search: $search) {
            __typename
            total_count
            page_info {
                current_page
                page_size
            }
            ...Aggregation
            items{
                    ...ProductInfo
                }
        }
    }
    ${LISTING_PRODUCTS_FRAGMENT}
    ${AGGREGATIONS_FRAGMENT}
    `;

