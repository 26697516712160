import React from 'react';

/**
 * Return list of links for preconnect to optimize page speed load time
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PreConnect = () => {
  return (
    <>
      <link rel="preconnect" href="https://omara.cdn-cnj.si" />
      <link rel="preconnect" href="https://apis.google.com" />
      <link rel="preconnect" href="https://feature.emmezeta.hr" />
    </>
  );
};

export default PreConnect;
