import React from 'react';
import PageSize from "../Category/PageSize/PageSize";
import SortProducts from "../Category/SortProducts/SortProducts";
import {useListingContext} from "../../context/ListingContext";

const ListingNav = ({showSorting=true, children}) => {

    const {
        listingParams,
        setListingParams
    } = useListingContext();

    const onPageSizeChange = e => {
        listingParams.pageSize = parseInt(e.target.value, 10);
        listingParams.currentPage = 1;
        setListingParams({...listingParams})
    };


    const onSortChange = e => {
        const [name, value] = e.target.value.split('-');
        listingParams.currentPage = 1;
        listingParams.sort = {};
        if (name !== 'default') {
            listingParams.sort[name] = value;
        }
        setListingParams({...listingParams})
    };

    return (
        <div className="category__sort">

            <PageSize
                pageSize={listingParams.pageSize}
                onPageSizeChange={onPageSizeChange}
            />
            {children}
            {showSorting && <SortProducts
                sorting={{
                    product_list_order: listingParams.sort,
                    product_list_dir: listingParams.sort,
                }}
                onSortChange={onSortChange}
                isSearch={false}
            />}
        </div>
    )
}

export default ListingNav