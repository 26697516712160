import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import AddToCartMessagePopup from '../components/AddToCartMessagePopup/AddToCartMessagePopup';

const Context = createContext();

const stripGQLPrefix = message => {
  const regex = /^\s*GraphQL Error:\s+(?:Unable to place order:\s+)?([\s\S]+)$/i;
  const match = message.match(regex);
  return match ? match[1] : message;
};

export const usePopup = () => {
  return useContext(Context);
};

export const PopupProvider = ({ children }) => {
  const [addToCartPopupProps, setAddToCartPopupProps] = useState(null);

  const timerRef = useRef();

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const showAddToCartPopupMessage = useCallback((message, type, ms = 2000) => {
    setAddToCartPopupProps({ message: stripGQLPrefix(message), type });

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setAddToCartPopupProps(null);
    }, ms);
  }, []);

  const value = useMemo(
    () => ({
      showAddToCartPopupMessage,
    }),
    [],
  );

  return (
    <Context.Provider value={value}>
      {children}

      {addToCartPopupProps && createPortal(<AddToCartMessagePopup {...addToCartPopupProps} />, document.body)}
    </Context.Provider>
  );
};
