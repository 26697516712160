import React, {useRef} from 'react';
import loadable from '@loadable/component';
import './ListingProducts.scss';
import {useListingContext} from '../../context/ListingContext';
import EmptyListing from "../EmptyListing/EmptyListing";

const ReactPaginate = loadable(() => import('react-paginate'));
const Products = loadable(() => import('../../components/Category/Products/Products'));
export default () => {

    const {
        products,
        listingParams,
        setListingParams,
        loading
    } = useListingContext();


    const ref = useRef(null);

    const isEmpty = products.length == 0;


    if (isEmpty && !loading) {
        return (
            <EmptyListing/>
        );
    }

    return (
        <div ref={ref}>
        <Products items={products}/>
        </div>
    )

};
