import React from 'react';
import { H2, Box, Link } from '@deity-io/falcon-ui';
import NotFoundPage from "../routes/NotFoundPage/NotFoundPage";

// based on https://reactjs.org/docs/error-boundaries.html
export class ErrorBoundary extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service

        this.setState({ error });
        this.setState({ info });
        console.error(error, info);
    }

    render() {
        if (this.state.hasError) {
            return (
                <NotFoundPage/>
            )
            return (
                <Box m="md" css={{ textAlign: 'center' }}>
                    <H2 mb="md">Something went wrong....</H2>
                    <Box>{this.state.error}</Box>
                    <Box>{this.state.info}</Box>
                    <Link onClick={() => window.location.reload()}>Refresh</Link>
                </Box>
            );
        }

        return this.props.children;
    }
}
