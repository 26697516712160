import { UPDATE_CART } from '../queries/cart';
import useCartMutation from './useCartMutation';

export const useUpdateCartItem = () => {
  const [updateCart, updateCartResult] = useCartMutation(UPDATE_CART);

  const updateCartItems = cartItems => {
    return updateCart({
      variables: { cartItems },
    });
  };

  return [updateCartItems, updateCartResult];
};
