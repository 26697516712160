import Cookies from 'universal-cookie';

const AUTH_TOKEN = 'auth-token';
const NEW_TOKEN = 'NEW_TOKEN';
const PHPSESSID = 'PHPSESSID';

const cookies = new Cookies();

export const getToken = () => cookies.get(AUTH_TOKEN);
export const setToken = (token, cookie = null) => {
    cookies.set(NEW_TOKEN, token, {
        path: '/',
        maxAge: 3600,
        domain: '.emmezeta.hr',
        sameSite: 'none',
        secure: true,
    });

    if (cookie) {
        cookies.set(PHPSESSID, cookie.replace('PHPSESSID=', ''), {
            path: '/',
            maxAge: 3600,
            domain: '.emmezeta.hr',
            sameSite: 'none',
            secure: true,
        });
    }
    cookies.set(AUTH_TOKEN, token, { path: '/', maxAge: 3600 });
};

export const deleteToken = () => {
    cookies.remove(AUTH_TOKEN, { path: '/' });
    cookies.remove(PHPSESSID, { path: '/' });
}
