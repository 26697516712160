import React from 'react';
import clsx from 'clsx';

import './TextButton.scss'

const TextButton = ({children, className, ...rest}) => {
  return (
    <button className={clsx(className && className, 'text-button', )} {...rest}>
      {children}
    </button>
  );
};

export default TextButton;
