import React, {createContext, useContext, useEffect, useState} from 'react';
import {useApolloClient} from "@apollo/react-hooks";
import {PRODUCT_LISTING} from '../routes/routeQuery';
import {useHistory} from "react-router-dom";
import {VIEW_MORE_FILTER} from "../components/Category/Filter/FilterTypes/FilterOtherType";
import {useQueryListing} from "../hooks/useQueryListing";
import {SchemaContext} from "./shemaContext";

export const defaultListingData = {
    totalPages: 0,
    totalCount: 0,
    currentPage: 1,
    pageSize: 0,
};
export const defaultSort = {};
export const defaultListingParams = {
    currentPage: 1,
    pageSize: 12,
    sort: defaultSort,
    search: "",
    filters: []
};

const defaultInitialParams = {filters: [], params: {...defaultListingParams}};

const ListingContext = createContext();


export function ListingContextProvider(props) {
    const {getFilterType}=useContext(SchemaContext)
    const queryClient = useApolloClient();
    const [initialListingParams, setInitialListingParams] = useState([])
    const [listingParams, setListingParams] = useState(defaultListingParams);
    const [aggregations, setAggregations] = useState([]);
    const [products, setProducts] = useState([]);
    const [listingData, setListingData] = useState({...defaultListingData});
    const [initialParams, setInitialParams] = useState(defaultInitialParams)
    const [loading, setLoading] = useState(true)
    const history = useHistory();


    const resetListing = (initialParams) => {
        setAggregations([]);
        setListingData(defaultListingData);
        setProducts([])
        setInitialListingParams(initialParams);
    }

    const initListing = (params) => {
        const newListingParams = {
            currentPage: 1,
            pageSize: 12,
            sort: defaultSort,
            search: "",
            filters: []
        };
        params.forEach(param => {
            if (newListingParams.hasOwnProperty(param.key)) {
                newListingParams[param.key] = param.value;
            } else {
                newListingParams.filters = newListingParams.filters.concat([
                    Object.assign({}, param, {inputType: getFilterType(param.key)})
                ])
            }
        })
        return newListingParams;
    }


    useEffect(() => {
        if (listingParams !== defaultListingParams) {
            loadProducts();
        }
    }, [listingParams])

    const buildQuery = (_listingParams) => {
        const uniqueKeys = [];
        const {filters} = _listingParams;
        filters.forEach((filter) => {
            if (!uniqueKeys.includes(filter.key)) {
                uniqueKeys.push(filter.key);
            }
        })
        const finalFilter = {};
        for (const key of uniqueKeys) {
            const paramValues = filters.filter(x => x.key === key);
            const paramCount = +paramValues.length;
            const {inputType} = paramValues.find(x => x); // Get first valid non falsy entry and fetch its input type

            if (inputType === 'FilterRangeTypeInput') {
                const rangeValue = paramValues.find(x => x).value.split('-');
                finalFilter[key] = {from: rangeValue[0], to: rangeValue[1]};
            }

            if (inputType === 'FilterMatchTypeInput') {
                // Combine all selected values into one string, because the FilterMatchTypeInput only allows strings
                finalFilter[key] = {match: paramValues.map(x => x.value).join(' ')}; // buildFilter(key, value, inputType)
            }

            if (inputType === 'FilterEqualTypeInput') {
                // Either eq or in based on number of values
                if (paramCount === 1) {
                    finalFilter[key] = {eq: paramValues.find(x => x).value};
                } else {
                    finalFilter[key] = {in: paramValues.map(x => x.value)};
                }
            }
        }
        _listingParams.currentPage = Math.max(_listingParams.currentPage, 1);
        const queryParams = Object.assign(
                {},
                _listingParams,
                {filter: finalFilter},
            )
        ;
        return queryParams;
    }

    const buildUrl = () => {
        const queryStringParams = new URLSearchParams();
        if (listingParams.search) {
            queryStringParams.append('q', listingParams.search);
        }
        listingParams.filters.forEach(param => {
            const _f = [...initialListingParams].find((_param) => {
                return _param.key == param.key && _param.value == param.value
            });
            if (!_f) {
                queryStringParams.append(param.key, param.value);
            }
        })
        if (listingParams.currentPage > 1) {
            queryStringParams.append('p', listingParams.currentPage);
        }
        if (listingParams.pageSize != 12) {
            queryStringParams.append('page-size', listingParams.pageSize);
        }
        if (listingParams.sort) {
            for (const [key, value] of Object.entries(listingParams.sort)) {
                queryStringParams.append('product_list_order', key);
                queryStringParams.append('product_list_dir', value);
            }
        }
        initialListingParams.forEach((filter) => {
            if (queryStringParams.has(filter.key) && queryStringParams.get(filter.key) == filter.value) {
                queryStringParams.delete(filter.key)
            }
        })
        history.push(`?${queryStringParams.toString()}`)
    }
    const loadProducts = async () => {
        try {
            setLoading(true)
            const {data} = await queryClient.query(
                {
                    query: PRODUCT_LISTING,
                    ssr: true,
                    variables: buildQuery(listingParams),
                    notifyOnNetworkStatusChange: true,
                }
            )
            if (data.products.items.length) {
                const listData = {
                    totalPages: Math.ceil(data.products.total_count / data.products.page_info.page_size),
                    totalCount: data.products.total_count,
                    currentPage: data.products.page_info.current_page,
                    pageSize: data.products.page_info.page_size,
                };
                const out = Object.assign({}, listingData, listData);
                setListingData(out);
                setProducts(data.products.items);
                if (aggregations.length == 0) {
                    setAggregations(data.products.aggregations.filter((aggregation)=>{
                        return aggregation['attribute_code']!='category_id'
                    }));
                }
                buildUrl()
            }
            setLoading(false)
        } catch (error) {
            console.error('Błąd:', error);
        }
    }
    const loadFilters = async (attribute_code) => {
        const {filter, search} = buildQuery(initListing(initialListingParams));
        return queryClient.query({
            query: VIEW_MORE_FILTER,
            variables: {
                filter: filter,
                search: search,
                filterName: attribute_code
            }
        }).then((data) => {
            const items = [];
            data.data.viewMoreFilter.aggregations.forEach((data) => {
                data.options.forEach((item) => {
                    items.push(item)
                })
            })
            return items;
        });
    }

    const data = {
        aggregations: aggregations,
        products: products,
        listingData: listingData,
        listingParams: listingParams,
        setListingParams: setListingParams,
        loadProducts: loadProducts,
        initialParams: initialParams,
        setInitialParams: setInitialParams,
        initialListingParams: initialListingParams,
        setInitialListingParams: setInitialListingParams,
        initListing: initListing,
        loadFilters: loadFilters,
        resetListing: resetListing,
        loading: loading
    };

    return (
        <ListingContext.Provider value={data}>
            {props.children}
        </ListingContext.Provider>
    );
}


export function useListingContext() {
    return useContext(ListingContext);
};
