import gql from 'graphql-tag';

export const GET_CUSTOMER = gql`
  query {
    customer {
      firstname
      is_subscribed
      is_admin
      lastname
      email
      addresses {
        id
        firstname
        lastname
        street
        city
        country_id
        country_code
        postcode
        telephone
        company
        vat_id
        default_billing
        default_shipping
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation ($input: CustomerCreateInput!) {
    createCustomerV2(input: $input) {
      customer {
        firstname
        lastname
        is_subscribed
      }
    }
  }
`;

export const GET_CARD = gql`
  query {
    historyCustomerLoyaltyCard {
      message
      success
      loyalty {
        memberID
        cardID
        saldoPoints
        isActive
        store
        transID
        transDate
        description
        income
        expense
      }
    }
  }
`;

export const CREATE_CARD = gql`
  mutation (
    $firstname: String!
    $lastname: String!
    $street: String!
    $streetNumber: String!
    $zip: String!
    $city: String!
    $country: String!
    $telephone: String!
    $isMobile: Boolean!
    $gender: String!
    $email: String!
    $dob: String!
  ) {
    createCustomerLoyaltyCard(
      firstname: $firstname
      lastname: $lastname
      street: $street
      streetNumber: $streetNumber
      zip: $zip
      city: $city
      country: $country
      telephone: $telephone
      isMobile: $isMobile
      gender: $gender
      email: $email
      dob: $dob
    ) {
      message
    }
  }
`;

export const CONNECT_CARD = gql`
  mutation (
    $cardId: String!
    $billId: String!
    $iagree: Boolean!
  ) {
    connectCustomerLoyaltyCard(
      cardId: $cardId
      billId: $billId
      iagree: $iagree
    ) {
      message
      result
    }
  }
`

export const CITY_BY_POSTCODE = gql`
  query ($city: String, $postCode: Int, $pageSize: Int = 50) {
    citiesByPostcode(city: $city, postCode: $postCode, pageSize: $pageSize) {
      city
      postcode
    }
  }
`;

export const IS_CUSTOMER_SUBSCRIBED = gql`
  query {
    isSubscribed {
      is_subscribed
    }
  }
`;

