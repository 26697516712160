import React, {useState} from 'react';
import {
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import {I18n, T} from '@deity-io/falcon-i18n';
import gql from "graphql-tag";
import {Loader} from "@deity-io/falcon-ecommerce-uikit";
import {debounce} from '../../../../utils/debounce';
import {useListingContext} from "../../../../context/ListingContext";

export const VIEW_MORE_FILTER = gql`
    query viewMoreFilter(
        $filterName: String!
        $search: String
        $filter: ProductAttributeFilterInput
    ) {
        viewMoreFilter(filterName: $filterName, search: $search, filter: $filter) {
            aggregations {
                attribute_code
                count
                has_more
                label
                options {
                    count
                    label
                    value
                }
                position
                rel_nofollow
            }
        }
    }
`;

/**
 * Proxy function for atob
 * @param base64
 * @returns {string}
 */
const atobp = (base64) => {
    if(typeof atob === "undefined") {
        return Buffer.from(base64, 'base64').toString('utf-8');
    }
    return atob(base64);
}


const FilterItem = (props) => {
    const [searchItem, setSearchItem] = useState('');
    const client = props.client;
    const builtFilter = props.builtFilter;
    const uid = props.uid;
    const attribute_code = props.aggregation.attribute_code;
    const label = props.aggregation.label;
    const type = props.type;
    let searchEnabled = props.type !== 'FilterRangeTypeInput'; // TODO: Implement

    if (props.aggregation.options.length<=4){
        searchEnabled=false
    }
    // Store all items and displayed items, both will be initialized with same data
    const _state = {
        items: [...props.aggregation.options],
        displayPlaceholder: props.aggregation.options
            .slice(0, 2)
            .map(x => x.label)
            .join(', '),
        displayedItems: [...props.aggregation.options],
        additionalOptions: [],
        has_more: props.aggregation.has_more,
        loading: false
    };

    const [hasMore, setHasMore] = useState(props.aggregation.has_more)
    const [options, setOptions] = useState([...props.aggregation.options]);
    const [state, setState] = useState(_state);
    const {
        setInitialListingParams,
        loadFilters
    } = useListingContext();

    const isChecked = (inputKey, inputValue) => {
        return props.initialState.find(({
                                            key,
                                            value
                                        }) => key === inputKey && value === inputValue) ? 'checked' : '';
    }

    const loadMore = () => {
        loadFilters(attribute_code).then((items) => {
            setOptions(items);
            setHasMore(false);
        })
    }

    const showLess = () => {
        // Reset to old state
        setState((currentState) => {
            return ({
                ...currentState,
                items: props.aggregation.options,
                displayedItems: props.aggregation.options,
                has_more: true
            })
        })
    }

    const getMoreButton = () => {
        // We don't have any lazy-loadable attrs
        if (!hasMore) {
            return null;
        }

        if (state.has_more) {
            return <a onClick={() => loadMore()}><T id={"category.filterMore"}/></a>;
        }
        return <a onClick={() => showLess()}><T id={"category.filterLess"}/></a>;
    }


    const search = ({target}) => {
        setSearchItem(target.value)
        loadFilters(attribute_code).then((items) => {
            setOptions(items);
            setHasMore(false);
        })

        //
        // const slugify = text => {
        //     return text
        //         .toString()
        //         .toLowerCase()
        //         .replace(/\s+/g, '-') // Replace spaces with -
        //         .replace(/[^\w\u0400-\u052F\u2DE0-\u2DFF\uA640-\uA69F'\-]+/g, '') // Remove all non-word chars
        //         .replace(/\-\-+/g, '-') // Replace multiple - with single -
        //         .replace(/^-+/, ''); // Trim - from start of text
        // };
        //
        // const searchTokens = slugify(target.value).split('-');
        // const lastSearchToken = searchTokens.splice(-1, 1)[0];
        //
        // // Have to query for search params to work through all of the results
        // setState((currentState) => {
        //     return ({...currentState, loading: true})
        // })
        // client.query({
        //     query: VIEW_MORE_FILTER,
        //     variables: {
        //         ...builtFilter,
        //         filterName: attribute_code
        //     }
        // }).then((searchResult) => {
        //     const list = searchResult.data.viewMoreFilter.aggregations[0]
        //     const items = list.options.filter(item => {
        //         let isValidItem = true;
        //         const itemTokens = slugify(item.label).split('-');
        //
        //         searchTokens.forEach(currentToken => {
        //             if (itemTokens.indexOf(currentToken) === -1) {
        //                 isValidItem = false;
        //             }
        //         });
        //
        //         if (isValidItem && lastSearchToken) {
        //             const ngrams = itemTokens.map(token => token.substring(0, lastSearchToken.length));
        //             isValidItem = ngrams.indexOf(lastSearchToken) !== -1;
        //         }
        //
        //         return isValidItem;
        //     });
        //
        //     // Set the displayed items
        //     setState({
        //         displayedItems: items,
        //         loading: false
        //     });
        // })
    };

    return (
        <>
            <AccordionItem uuid={attribute_code} key={attribute_code}>
                <AccordionItemHeading>
                    <AccordionItemButton>{label}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    {searchEnabled && (
                        <I18n>
                            {t => (
                                <input placeholder={`${t('form.search')} (${state.displayPlaceholder})`}
                                       value={searchItem}
                                       onChange={(e) => debounce(search(e), 500)}/>
                            )}
                        </I18n>
                    )}
                    {
                        state.loading && <Loader/>
                    }
                    {
                        !state.loading && <>
                            <ul>
                                {options.filter((label) => {
                                        if (searchItem == '') {
                                            return true;
                                        }

                                        return label.label.toLowerCase().includes(searchItem.toLowerCase());
                                    }
                                ).map(({label, count, value}) => {
                                    return (
                                        <li key={label}>
                                            <input
                                                id={label}
                                                type="checkbox"
                                                className="filterContentCheckbox"
                                                value={value}
                                                checked={isChecked(attribute_code, value)}
                                                onChange={({target}) => props.setUrlParam(attribute_code, value)}
                                            />
                                            <label htmlFor={label}>
                                                <span>{label}</span>
                                                <span> ({count}) </span>
                                            </label>
                                        </li>
                                    );
                                })}
                                {!state.displayedItems.length ?
                                    <li key={"no-result"}><T id={"category.filterNoResults"}/></li> : null}
                            </ul>
                            {getMoreButton()}
                        </>
                    }
                </AccordionItemPanel>
            </AccordionItem>
        </>
    );

}

export default FilterItem;
