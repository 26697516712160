import gql from 'graphql-tag';

export const GET_CMS_BLOCKS = gql`
  query getCmsBlocks($identifiers: [String]) {
    cmsBlocks(identifiers: $identifiers) {
      items {
        title
        identifier
        content
        content_json
        hydrated_content
      }
    }
  }
`;
