import React from 'react';
import './Compare.scss';
import {useCompare} from "../../hooks/useCompare";
import {Link, Link as RouterLink} from 'react-router-dom';
import {T} from "@deity-io/falcon-i18n";
import Button from "../UI/Button/Button";

const CompareBlock = () => {
    const {compareProducts, removeCompare} = useCompare();

    if (compareProducts.length == 0) {
        return null
    }
    return (
        <div id='compare-wrapper'>
            <div id='compare-context'>
                <ul>
                    {
                        compareProducts.map((product) => {
                            return (
                                <li className='item'>
                                    <Link to={`/${product.canonical_url}`}>
                                    <div class='_picture'
                                         style={{backgroundImage:`url(${product.thumbnail})`}}
                                    ></div>
                                    </Link>
                                    <Link to={`/${product.canonical_url}`}>
                                    <span className='name'>{product.name}</span>
                                    </Link>
                                    <span className='remove' onClick={() => removeCompare(product.sku)}>X</span>
                                </li>
                            )
                        })
                    }

                {compareProducts.length >= 2 &&
                    <li>

                        <Link to="/compare" as={RouterLink}><Button trait="profile-hover" className="empty-cart__button"><T id="Compare"/></Button></Link>
                    </li>
                    }
                </ul>
            </div>
        </div>
    )
}

export default CompareBlock;
