import { Loader } from '@deity-io/falcon-ecommerce-uikit';
import loadable from '@loadable/component';
import React from 'react';

/**
 * loadable define code splitting point with lazy `import`, it needs to be exported as `default` and imported via `loadable` name to be compatible with SSR
 * @see https://github.com/smooth-code/loadable-components/issues/100#issuecomment-441192303
 * @param {() => Promise<any>} component module which should be lazy imported
 * @param {import('@loadable/component').Options} options
 * @returns {any} content of lazy imported module
 */
export default (component, options = {}) =>
    loadable(component, {
        fallback: <></>, // This is the default "placeholder" - commented it out due to layout issues
        ...options,
    });
