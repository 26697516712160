import React from "react";
import ProductPrice from "../ProductPrice/ProductPrice";
import {T} from "@deity-io/falcon-i18n";
import readConfig from "../../../utils/readClientConfiguration";
import productionConfiguration from "../../../../config/default.json";

const baseClassName = 'product-item';

const countryCode = readConfig(productionConfiguration, 'countryCode');
const isRs = countryCode.includes('RS');

const PriceBlock = ({product, hideLast30 = false, hideLabels = false, shortLabel=false}) => {

    switch (product.__typename) {
        case "GroupedProduct":
            return (
                <div className="product-item__price-box">
                    <div className="product-price__price price"><T id="product.pricePerElements"/></div>
                </div>
            )
            break;
    }

    if (!product.prices) {
        product.prices = [];
    }

    const hasPromoPrice = product.prices.filter((price) => {
        return ['simple_loyalty'].includes(price.price_type);
    }).length > 0;

    const hasDiscount = product.prices.filter((price) => {
        return ['simple_clearance', 'promo_price', 'simple_promo'].includes(price.price_type);
    }).length > 0;

    return (
        <div className={
            [
                `${baseClassName}__price-box`,
                hasPromoPrice ? 'hasPromoPrice' : '',
                hasDiscount ? 'hasDiscount' : '',
            ].join(' ')}>
            {product.prices.map((price, index) => {
                return (
                    <ProductPrice price={price} key={index} hideLabels={hideLabels} shortLabel={shortLabel}/>
                )
            })}
        </div>
    )
}

export default PriceBlock