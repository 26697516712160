import React, {createContext, useContext, useEffect, useState} from 'react';
import {useQuery} from 'react-apollo';
import {useAddProductToCart} from '../hooks/useAddProductToCart';
import {useUpdateCartItem} from '../hooks/useUpdateCartItem';
import {GET_CART} from '../queries/cart';
import {METHOD_MATRIX} from "../utils/checkout";

const CartContext = createContext(null);

export const CartConsumer = CartContext.Consumer;

export const useCartContext = () => {
    return useContext(CartContext);
};

export const defaultCart = {
    'items': [],
    'prices': {
        'grand_total': 0
    }
}

export const CartContextProvider = ({children}) => {

    const getCartResult = useQuery(GET_CART, {
        fetchPolicy: 'cache-and-network',
        ssr: false,
        onCompleted: data => {
            setCart(data.cart)
        }
    });

    const [cart, setCart] = useState()
    const [method, setMethod] = useState(METHOD_MATRIX);

    const [addProductToCart, addProductResult] = useAddProductToCart();

    const [updateCartItems, updateCartResult] = useUpdateCartItem();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [orderNote, setOrderNote] = useState('');

    const loading = getCartResult.loading || addProductResult.loading || updateCartResult.loading;
    const updateCartItem = (id, quantity) => updateCartItems([{cart_item_id: +id, quantity}]);

    const address = cart && cart.shipping_addresses && cart.shipping_addresses[0];
    const currentMethod = address && address.selected_shipping_method && address.selected_shipping_method.carrier_code;

    const resetCart=()=>{
        setCart(defaultCart)
    }
    useEffect(() => {
        if (address && currentMethod) {
            setMethod(currentMethod);
        }
    }, [currentMethod]);

    const handleProductAdd = (sku, quantity) => {
        const itemInCart = false;

        if (cart) {
            cart.items.find(item => item.product.sku === sku);
        }

        if (itemInCart) {
            return updateCartItem(itemInCart.id, itemInCart.quantity + quantity);
        }

        return addProductToCart(sku, quantity);
    };
    return (
        <CartContext.Provider
            value={{
                cart,
                method,
                setMethod,
                phoneNumber,
                setPhoneNumber,
                orderNote,
                setOrderNote,
                refetchCart: () => getCartResult.refetch(),
                cartStatus: getCartResult.networkStatus,
                loading,
                handleProductAdd,
                updateCartItem,
                updateCartItems,
                resetCart
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
