import React, {useContext, useEffect, useState} from "react";
import {useListingContext} from "../../context/ListingContext";
import {SchemaContext} from "../../context/shemaContext";
import {I18nContext} from "@deity-io/falcon-i18n";

const SelectFilter = ({filter, filterHandle}) => {
    const [selectedValues, setSelectedValues] = useState([])
    const { t } = useContext(I18nContext);
    const {
        listingParams
    } = useListingContext();
    const currentFilters = listingParams.filters;
    const {getFilterType} = useContext(SchemaContext)

    useEffect(() => {
        const output = []
        currentFilters.forEach((_filter) => {
            if (_filter.key == filter.attribute_code) {
                output.push(_filter.value)
            }
        })
        setSelectedValues(output)
    }, [currentFilters])

    const onChangeHandler = (event, filter) => {
        const newFilters = currentFilters.slice().filter((_filter) => _filter.key != filter.attribute_code);

        if (event.target.value) {
            newFilters.push({
                key: filter.attribute_code,
                value: event.target.value,
                inputType: getFilterType(filter.attribute_code)
            })
        }
        filterHandle(newFilters)
    }

    return (
        <select
            className='filter-select'
            name={filter.attribute_code}
            onChange={(e) => onChangeHandler(e, filter)}
        >
            <option value=''>{t('Select')} {filter.label}</option>
            {filter.options.map((option) => {
                return <option
                    selected={selectedValues.includes(option.value)}
                    value={option.value}
                >{option.label}</option>
            })}
        </select>
    )
}

export default SelectFilter