export const useQueryListing = () => {
    const buildListingParamsFromQuery = (data) => {

        const {search} = location;
        const parsedParams = new URLSearchParams(search);
        const out=[...data]
        for (const [key, value] of parsedParams.entries()) {
            switch (key) {
                case 'q':
                    out.push({key: 'search', value: value})
                    break;
                case 'product_list_dir':
                case 'product_list_order':
                    // data.sort({key: key, value: value})
                    break;
                case 'page-size':
                case 'pageSize':
                    out.push({key: 'pageSize', value: parseInt(value)})
                    break;
                case 'p':
                    out.push({key: 'currentPage', value: parseInt(value)})
                    break;
                default:
                    out.push({
                        key: key, value: value
                    })
            }
        }

        if(parsedParams.has('product_list_order') && parsedParams.has('product_list_dir')) {
            const _sort = {};
            _sort[parsedParams.get('product_list_order')] = parsedParams.get('product_list_dir')

            out.push({
                key: 'sort', value: _sort
            })
        }
        return out
    }

    return {buildListingParamsFromQuery};
};
