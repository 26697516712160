import InfoIcon from "../../../icons/InfoIcon";
import React from "react";
import useResponsive from "../../../hooks/useResponsive";

const PriceLabel = ({price, shortLabel=false}) => {

    if (!price.label) {
        return null
    }

    const getLabel = () => {
        if (shortLabel) {
            return `-${price.percentage}%`
        }
        return price.label
    }

    return (
        <div className={'promo_price_label'}
             style={{
                 backgroundColor: price.bg_color,
                 color: price.txt_color
             }}>{getLabel()}
            {!shortLabel &&
                <InfoIcon className={'info-icon'} fill={price.txt_color}/>
            }

            {!shortLabel && price.description && price.description.length > 0 &&
                <div className={'price_description_wrapper'}>
                    <ul className={'promo_price_tooltip'}>
                        {price.description.map((item, index) => {
                            return (
                                <li key={index}>{item}</li>
                            )
                        })}</ul>
                </div>
            }
        </div>
    )
}

export default PriceLabel