import * as React from 'react';
import { Range } from 'react-range';
import './RangeSlider.scss';

// TODO: Handle the price changing better
class RangeSlider extends React.Component {
    state = { values: [
        this.props.initialData && this.props.initialData[0] >= this.props.minValue && this.props.initialData[0] || this.props.minValue,
        this.props.initialData && this.props.initialData[1] <= this.props.maxValue && this.props.initialData[1] || this.props.maxValue
        ] };
    render() {

        const minValue = this.state.values[0];
        const maxValue = this.state.values[1];
        const count = this.props.options.filter(x => (+x.value >= minValue && +x.value <= maxValue)).reduce((x, y) => x + y.count, 0);
        const attribute_code = this.props.attribute_code;
        const setUrlParam = this.props.setUrlParam;

        // TODO: Add mjerna jedinica
        return (
            <div className="priceFilter">
                <span>{`${minValue} - ${maxValue}`}</span>
                <Range
                    step={10}
                    min={this.props.minValue}
                    max={this.props.maxValue}
                    values={this.state.values}
                    onChange={(values) => this.setState({ values })}
                    allowOverlap={true}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            className="rangeSlider"
                            style={{
                                ...props.style,
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            {...props}
                            className="renderThumb"
                            style={{
                                ...props.style,

                            }}
                        />
                    )}
                />
                {count >= 1 ? <span>{count} proizvoda</span> : <span>Nema proizvoda u odabranom rasponu.</span>}
                <br/>
                <button className="rangeSliderButton" onClick={({target}) => setUrlParam(attribute_code, `${minValue}-${maxValue}`, true)}>OK</button>
            </div>
        );
    }
}

export default RangeSlider;
