import gql from 'graphql-tag';
import {CART_FRAGMENT} from "../screens/Cart/queriesCart";

export const ESTIMATE_SHIPPING_METHODS = gql`
  query EstimateShippingMethods($address: AddressInput) {
    estimateShippingMethods(input: { address: $address }) {
      carrierTitle
      amount
      currency
      carrierCode
      methodCode
      methodTitle
      priceExclTax
      priceInclTax
    }
  }
`;

export const GET_CART = gql`
  query Cart {
    cart {
      id
      email
      cart_id
      total_quantity
      selected_payment_method {
        code
        purchase_order_number
        title
      }
      applied_coupons {
        code
      }
      extension_attributes {
        loyalty_card_used
        loyalty_points_used
        loyalty_points_earned
      }
      available_payment_methods {
        code
        title
      }
      prices {
        grand_total {
          currency
          value
        }
        subtotal_including_tax {
          currency
          value
        }
        subtotal_original_including_tax {
            value
          currency
        }
        applied_taxes {
          amount {
            value
          }
        }
        discounts {
          amount {
            value
          }
          label
          name
          name_html
        }
      }
      items {
        id
        uid
        quantity
            prices{
                price{
                    currency
                    value
                }
                priceIncludingTax{
                  currency
                  value
                }
                row_total_including_tax{
                  currency
                  value
                }
            }
        ... on SimpleCartItem {
        
          gift_wrapping {
            design
            image {
              label
              url
            }
            price {
              currency
              value
            }
            uid
          }
          available_gift_wrapping {
            design
            image {
              label
              url
            }
            price {
              currency
              value
            }
            uid
          }
        }
        product {
          name
          brand
          barcode
          categories {
            __typename
            name
          }
          sku
          days_to_delivery
          is_on_order_only
          canonical_url
          stock_status
          advanced_product_inventory {
            enable_qty_increments
            qty_increments
            unit
          }
          gift_wrapping_available
          ... on SimpleProduct {
            weight
          }
          price {
            regularPrice {
              amount {
                currency
                value
              }
            }
          }
          price_last_30
          prices {
            price_type
            amount
            unit
            old_amount
            currency_symbol
            percentage
            xumulus_rule_label
          }
          thumbnail {
            label
            url
          }

            categories {
                name
            }
          crosssell_products {
            sku
          }
        }
      }
      billing_address {
        customer_address_id
        city
        company
        vat_id
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        street
        telephone
      }
      shipping_addresses {
        customer_address_id
        city
        firstname
        lastname
        postcode
        street
        telephone
        pickup_location_code
        country {
          code
        }
        region {
          label
        }
        selected_shipping_method {
          amount {
            value
          }
          price_incl_tax {
            value
          }
          carrier_code
          carrier_title
          method_code
          method_title
        }
        available_shipping_methods {
          amount {
            currency
            value
          }
          available
          carrier_title
          carrier_code
          method_code
          method_title
        }
      }
    }
  }
`;

export const ADD_PRODUCT_TO_CART = gql`
  mutation($cart_items: [SimpleProductCartItemInput]!) {
    addSimpleProductsToCart(input: { cart_items: $cart_items }) {
      __typename
      cart {
        id
        __typename
        items {
          __typename
          product {
            __typename
            sku
          }
        }
      }
    }
  }
`;

export const NEW_MONRI_PAYMENT = gql`
  mutation($amount: Int!, $orderNumber: String!, $reservedOrderNumber: String!) {
    newMonriPayment(amount: $amount, orderNumber: $orderNumber, reservedOrderNumber: $reservedOrderNumber) {
      status
      id
      clientSecret
    }
  }
`;

export const NEW_MONRI_DIGEST = gql`
  mutation($amount: Int!, $orderNumber: String!) {
    newMonriDigest(amount: $amount, orderNumber: $orderNumber) {
      digest
    }
  }
`;

export const UPDATE_CART = gql`
  mutation($cartItems: [CartItemUpdateInput]!) {
    updateCartItems(input: { cart_items: $cartItems }) {
      cart {
        id
      }
    }
  }
`;

export const SET_GUEST_EMAIL = gql`
  mutation($input: SetGuestEmailOnCartInput) {
    setGuestEmailOnCart(input: $input) {
      cart {
        id
        email
      }
    }
  }
`;

export const SET_SHIPPING_ADDRESS = gql`
  mutation($input: SetShippingAddressesOnCartInput) {
    setShippingAddressesOnCart(input: $input) {
      cart {
        id
        shipping_addresses {
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_title
            carrier_code
            method_code
            method_title
          }
        }
      }
    }
  }
`;

export const SET_SHIPPING_METHOD = gql`
  mutation($input: SetShippingMethodsOnCartInput) {
    setShippingMethodsOnCart(input: $input) {
      cart {
        id
      }
    }
  }
`;

export const SET_BILLING_ADDRESS = gql`
  mutation($input: SetBillingAddressOnCartInput) {
    setBillingAddressOnCart(input: $input) {
      cart {
        id
      }
    }
  }
`;

export const SET_PAYMENT_METHOD = gql`
  mutation($input: SetPaymentMethodOnCartInput) {
    setPaymentMethodOnCart(input: $input) {
      cart {
        id
      }
    }
  }
`;

export const SET_ORDER_NOTE = gql`
  mutation($note: String!, $method: String!, $email: String!) {
    setOrderNote(note: $note, method: $method, email: $email)
  }
`;

export const APPLY_COUPON = gql`
  mutation($input: ApplyCouponToCartInput) {
    applyCouponToCart(input: $input) {
      cart {
        id
        items {
          prices {
            discounts {
              label
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_COUPON = gql`
  mutation removeCouponFromCart($input: RemoveCouponFromCartInput) {
    removeCouponFromCart(input: $input) {
      cart {
        id
      }
    }
  }
`;

export const PLACE_ORDER = gql`
  mutation placeOrder($input: PlaceOrderInput) {
    placeOrder(input: $input) {
      order {
        order_number
        frontend_id
      }
    }
  }
`;

export const CHECKOUT_AGREEMENTS = gql`
  query checkoutAgreements {
    checkoutAgreements {
      agreement_id
      checkbox_text
      content
      content_height
      is_html
      name
    }
  }
`;

export const UPDATE_PICKUP_STORE = gql`
  mutation($pickupStore: String!, $telephone: String!, $email: String!) {
    updatePickupStore(pickupStore: $pickupStore, telephone: $telephone, email: $email)
  }
`;


export const APPLY_LOYALTY_POINTS = gql`
  mutation($points: Int!) {
    applyLoyaltyPoints(points: $points)
  }
`;

export const CHECK_STOCK_STATUS = gql`
  query($skus: [String]!) {
    checkStock(skus: $skus) {
      availQTY
      deliveryTerm
      itemID
      webSiteCode
    }
  }
`;
