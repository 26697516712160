import React from 'react';
import TextButton from '../TextButton/TextButton';
import { T } from '@deity-io/falcon-i18n';

import './Modal.scss';

const Modal = ({ isOpen, onClose, children, isMobileFilter}) => {
  const close = e => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <>
      {isOpen ? (
          <>
            <div className="modal" onClick={!isMobileFilter && close}>
              <div className="modal__content-wrapper">
                <div className="modal__close">
                  {isMobileFilter &&
                    <TextButton onClick={onClose}>
                      <p className="modal__close-text"><T id="searchResult.filter" /></p>
                      <div className="modal__close-btn" />
                    </TextButton>
                  }
                  {!isMobileFilter &&
                    <TextButton onClick={onClose}>
                      <div className="modal__close-btn" />
                    </TextButton>
                  }
                </div>
                <div className="modal__content">{children}</div>
              </div>
            </div>
          </>
      ) : null}
    </>
  );
};

export default Modal;
