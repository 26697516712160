import React, {useContext} from 'react';
import {useListingContext} from "../../context/ListingContext";
import {SchemaContext} from "../../context/shemaContext";
import SelectFilter from "./SelectFilter";

const HorizontalFilter = ({filters = [], filterHandle}) => {
    const {
        aggregations,
    } = useListingContext();


    return (
        <div className='horizontal-filters-wrapper'>
            {aggregations.filter((filter) => {
                return filters.includes(filter.attribute_code)
            }).map((filter) => {
                return (
                    <div className='horizontal-filters-item'>
                        <SelectFilter filter={filter} filterHandle={filterHandle}/>
                    </div>
                )
            })}
        </div>
    )
}

export default HorizontalFilter;
