import React from 'react';
import {Redirect, useLocation} from "react-router-dom";
import readConfig from "../utils/readClientConfiguration";
import productionConfiguration from "../../config/default.json";

const Media = () => {
    const cdnUrl = readConfig(productionConfiguration, 'cdn');
    const location = useLocation();
    const currentPath = location.pathname;

    const modifiedPath = cdnUrl + currentPath.replace('/image', '');

    return (
        <Redirect to={modifiedPath} push={true}/>
    )
}

export default Media
