import { useMutation } from 'react-apollo';
import { ESTIMATE_SHIPPING_METHODS, GET_CART } from '../queries/cart';

const useCartMutation = (mutation, { refetchQueries = [], ...options } = {}) => {
  return useMutation(mutation, {
    refetchQueries: [{ query: GET_CART }, { query: ESTIMATE_SHIPPING_METHODS }, 'Cart', ...refetchQueries],
    awaitRefetchQueries: true,
    ...options,
  });
};

export default useCartMutation;
