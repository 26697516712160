import React from 'react';
import { Link } from 'react-router-dom';
import { T } from '@deity-io/falcon-i18n';
import Image from '../../assets/img/emmezeta-404-uuuups.png';
import './NotFoundPage.scss';

const NotFoundPage = () => {
    return (
        <div className="not-found">

            <img src={Image} />

            <h2 className="not-found__title"><T id="notFound.title" /></h2>

            <div className="not-found__text">
                <p><T id="notFound.text1" /></p>
                <p><T id="notFound.text2" /></p>
                <p><T id="notFound.text3" /></p>
                <p><T id="notFound.text4" /></p>
            </div>

            <div className="not-found__button">
                <Link to="/"><p><T id="notFound.goBack" /></p></Link>
            </div>

        </div>
    )
}

export default NotFoundPage;
