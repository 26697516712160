import React, {useContext} from 'react';
import './Filter.scss';
import FilterItem from './FilterItem';
import {T} from '@deity-io/falcon-i18n';

import {Accordion} from 'react-accessible-accordion';
import {SchemaContext} from "../../../context/shemaContext";
import ListingNav from "../../ListingNav/ListingNav";
import HorizontalFilter from "../../ProductFilters/HorizontalFilter";


const Filter = ({aggregations, filterHandle, currentFilters}) => {
    const {getFilterType,schema}=useContext(SchemaContext)

    const usedFilters=currentFilters.filter(({key, value}) => {
        const aggregationData = aggregations.find(({attribute_code}) => attribute_code === key);
        return aggregationData;
    })

    const clearParams = () => {
        filterHandle([])
    }

    const setUrlParam = (attribute, value, force) => {
        const newFilters = currentFilters.slice();
        if (force) {
            newFilters.forEach((item, index) => {
                if (item.key == attribute) {
                    newFilters.splice(index,1);
                }
            })
        }
        let addItem = true;
        newFilters.forEach((item, index) => {
            if (item.key == attribute && item.value == value) {
                newFilters.splice(index, 1);
                addItem = false;
            }
        })
        if (addItem) {
            newFilters.push({key: attribute, value: value, inputType: getFilterType(attribute)})
        }
        filterHandle(newFilters)
    }


    if (!aggregations) {
        return <h1>Aggregations data missing</h1>
    }

    /**
     * Figure out the the type of the filter attribute (price range, match...)
     * @param field
     * @param data
     * @returns {string}
     */


        // Get the active expanded tabs
    const preExpanded = ((aggregations, initialState) => {
            try {
                return aggregations.slice(0, 3)
                    .map(({attribute_code}) => attribute_code)
                    .concat(initialState.map(({key}) => key))
            } catch (e) {
                // Messy code, just fail in a safe way
                return [];
            }
        })



    return (
        <>
            {!!usedFilters.length && <div className="currentFilters">
                <strong><T id={"category.filterCurrentShopping"}/></strong>

                <ul>
                    {
                        usedFilters.map(({key, value}) => {
                            const aggregationData = aggregations.find(({attribute_code}) => attribute_code === key);
                            return (<li key={`${key}_${value}`}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => setUrlParam(key, value, false)}>
                                {aggregationData.label} {value}
                            </li>)
                        })
                    }
                </ul>
                {/*<button onClick={() => clearParams()}>Očistite sve</button>*/}
            </div>}

            <div className="filterHeader"><strong>Filtrirajte rezultate</strong></div>

            <Accordion preExpanded={preExpanded(aggregations, currentFilters)}
                       allowMultipleExpanded={true}
                       allowZeroExpanded={true}>
                {aggregations.map(aggregation => {
                    if (!aggregation.options) {
                        return false;
                    }
                    if (aggregation.options.length == 1) {
                        return false;
                    }


                    // Check if that option is the only one and is attached to all items
                    // if (aggregation.options.length === 1) {
                    //     const currentlyActive = currentFilters.some(agg => agg.key === aggregation.attribute_code);
                    //     if ((aggregation.options[0].count === totalCount) && !currentlyActive) {
                    //         return false;
                    //     }
                    // }
                    const type = getFilterType(aggregation.attribute_code);
                    return (<FilterItem key={aggregation.attribute_code + "." + JSON.stringify(aggregation.options)}
                                        type={type}
                                        aggregation={aggregation}
                                        initialState={usedFilters}
                                        setUrlParam={setUrlParam}
                    />)
                })}
            </Accordion>
        </>
    );
};
export default Filter;