import React from "react";
import {useListingContext} from "../../context/ListingContext";
import ReactPaginate from "react-paginate";

const Paginator = ({handle}) => {

    const {
        totalPages = 0,
        currentPage = 1
    } = useListingContext().listingData

    const {
        listingParams,
        setListingParams
    } = useListingContext()

    const onPageChange = page => {
        const newPage = page.selected + 1;
        listingParams.currentPage = newPage;
        setListingParams({...listingParams})
    };

    if (totalPages <= 1 ) {
        return null
    }

    return (
        <ReactPaginate
            previousLabel={<div className="pagination__arrow_prev"/>}
            nextLabel={<div className="pagination__arrow_next"/>}
            breakLabel="..."
            forcePage={currentPage - 1}
            pageRangeDisplayed={4}
            marginPagesDisplayed={1}
            pageCount={totalPages}
            onPageChange={onPageChange}
            breakClassName="pagination__page"
            disabledClassName="disabled"
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            previousClassName="pagination__arrow"
            previousLinkClassName="pagination__arrow-link"
            nextClassName="pagination__arrow"
            nextLinkClassName="pagination__arrow-link"
            pageClassName="pagination__page"
            activeClassName="active-page"
            activeLinkClassName="active-link"
        />
    )
}
export default Paginator