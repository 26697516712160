import {T} from "@deity-io/falcon-i18n";
import QuarticonProductSlider from "../../renderComponents/ProductsSlider/QuarticonProductSlider";
import React from "react";

const EmptyListing = ()=>{
    return (
        <>
            <h1>
                <T id="category.noProductAlert"/>
            </h1>
            {<QuarticonProductSlider type="no_search" cnt={4}/>}

        </>
    );
}

export default EmptyListing;
